import { ReactNode } from 'react'
import { Backdrop, Box, useTheme } from '@mui/material'

type BackdropPopupProps = {
  openBackdrop: boolean
  children: ReactNode | ReactNode[]
}

export default function BackdropPopup({ openBackdrop, children }: BackdropPopupProps) {
  const theme = useTheme()
  return (
    <Backdrop open={openBackdrop} sx={{ zIndex: 2 }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          background: theme.palette.background.default,
          padding: '40px',
          borderRadius: '7px',
          zIndex: 2,
        }}
      >
        {children}
      </Box>
    </Backdrop>
  )
}
