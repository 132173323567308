import { Box, useTheme } from '@mui/material'
import {
  DeleteOutlineRounded as DeleteOutlineRoundedIcon,
  EditOutlined as EditOutlinedIcon,
} from '@mui/icons-material'

import { GridContainer, GridItem } from 'presentation/components/elements/grid'
import { TextParagraph } from 'presentation/components/elements/typography'
import { Item } from 'domain/entities/package'

type PackageFormAddItemProps = {
  handleDeleteItem: (item: string) => void
  itemList: Item[]
  handleSetElement: (item: Item) => void
}
export default function PackageFormAddItem({
  handleDeleteItem,
  itemList,
  handleSetElement,
}: PackageFormAddItemProps) {
  const theme = useTheme()

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginBottom: '15px',
        gap: '5px',
      }}
    >
      {itemList?.map((item: Item) => (
        <Box
          key={item.id}
          sx={{
            borderBottom: `0.1px solid ${theme.palette.info.main}`,
            width: '100%',
            padding: '3px 2px 3px 10px',
            borderRadius: '8px',
          }}
        >
          <GridContainer alignItems="center">
            <GridItem md={10}>
              <TextParagraph fontSize="12px">{item.name}</TextParagraph>
            </GridItem>
            <GridItem md={2}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  gap: '8px',
                  width: '100%',
                }}
              >
                <EditOutlinedIcon
                  color="info"
                  onClick={() => handleSetElement(item)}
                  sx={{ cursor: 'pointer' }}
                />
                <DeleteOutlineRoundedIcon
                  color="info"
                  onClick={() => handleDeleteItem(item.id)}
                  sx={{ cursor: 'pointer' }}
                />
              </Box>
            </GridItem>
          </GridContainer>
        </Box>
      ))}
    </Box>
  )
}
