const AUTHENTICATION = 'autenticacion'
const PRIVATE = 'privado'
const LOGIN = 'login'
const RECOVER_PASSWORD = 'recuperar-contrasena'
const FORGOT_PASSWORD = 'cambiar-contrasena'
const TERMS_CONDITIONS = 'terminos-condiciones'
const CONTACTUS = 'contactanos'
const FREQUENT_QUESTIONS = 'preguntas-frecuentes'
const DATA_PRIVACY_POLICY = 'politica-privacidad-datos'
const DOCUMENT_MANAGEMENT = 'adminstracion-documentos'
const INSTITUTION_ADMINISTRATION = 'adminstracion-instituciones'
const ADMIN_MANAGEMENT = 'gestion-administradores'
const PROMOTION = 'promocion'
const CREATE = 'crear'
const EDIT = 'editar'
export const CREATE_PROMOTION = `${CREATE}-${PROMOTION}`
export const EDIT_PROMOTION = `${EDIT}-${PROMOTION}`
export const LIST_PROMOTIONS = 'lista-promociones'
const PACKAGE_ADMINISTRATION = 'adminstracion-paquetes'
const DESTINATION_ADMINISTRATION = 'adminstracion-destinos'
export const urlRoutes = {
  public: {
    url: '/*',
    auth: {
      url: `/${AUTHENTICATION}/*`,
      login: {
        url: `/${LOGIN}`,
        urlTo: `/${AUTHENTICATION}/${LOGIN}`,
      },
      recoverPassword: {
        url: `/${RECOVER_PASSWORD}`,
        urlTo: `/${AUTHENTICATION}/${RECOVER_PASSWORD}`,
      },
      forgotPassword: {
        url: `/${FORGOT_PASSWORD}`,
        urlTo: `/${AUTHENTICATION}/${FORGOT_PASSWORD}`,
      },
    },
    footer: {
      url: `/*`,
      contactUs: {
        url: `/${CONTACTUS}`,
        urlTo: `/${CONTACTUS}`,
      },
      frequentQuestions: {
        url: `/${FREQUENT_QUESTIONS}`,
        urlTo: `/${FREQUENT_QUESTIONS}`,
      },
      termsAndConditions: {
        url: `/${TERMS_CONDITIONS}`,
        urlTo: `/${TERMS_CONDITIONS}`,
      },
      dataPrivacyPolicy: {
        url: `/${DATA_PRIVACY_POLICY}`,
        urlTo: `/${DATA_PRIVACY_POLICY}`,
      },
    },
  },
  private: {
    url: `/${PRIVATE}/*`,
    institutionAdministration: {
      url: `/${INSTITUTION_ADMINISTRATION}`,
      urlTo: `/${PRIVATE}/${INSTITUTION_ADMINISTRATION}`,
    },
    createInstitution: {
      url: `/${INSTITUTION_ADMINISTRATION}/${CREATE}`,
      urlTo: `/${PRIVATE}/${INSTITUTION_ADMINISTRATION}/${CREATE}`,
    },
    editInstitution: {
      url: `/${INSTITUTION_ADMINISTRATION}/${EDIT}/:institutionId`,
      urlTo: `/${PRIVATE}/${INSTITUTION_ADMINISTRATION}/${EDIT}`,
    },
    promotionAdministration: {
      url: `/${INSTITUTION_ADMINISTRATION}/:institutionId/${LIST_PROMOTIONS}`,
      urlTo: `/${PRIVATE}/${INSTITUTION_ADMINISTRATION}`,
    },
    createPromotion: {
      url: `/${INSTITUTION_ADMINISTRATION}/:institutionId/${LIST_PROMOTIONS}/${CREATE_PROMOTION}`,
      urlTo: `/${PRIVATE}/${INSTITUTION_ADMINISTRATION}`,
    },
    editPromotion: {
      url: `/${INSTITUTION_ADMINISTRATION}/:institutionId/${LIST_PROMOTIONS}/:promotionId/${EDIT_PROMOTION}`,
      urlTo: `/${PRIVATE}/${INSTITUTION_ADMINISTRATION}`,
    },
    documentManagement: {
      url: `/${DOCUMENT_MANAGEMENT}`,
      urlTo: `/${PRIVATE}/${DOCUMENT_MANAGEMENT}`,
    },
    adminManagement: {
      url: `/${ADMIN_MANAGEMENT}`,
      urlTo: `/${PRIVATE}/${ADMIN_MANAGEMENT}`,
    },
    creatAdmin: {
      url: `/${ADMIN_MANAGEMENT}/${CREATE}`,
      urlTo: `/${PRIVATE}/${ADMIN_MANAGEMENT}/${CREATE}`,
    },
    packageAdministration: {
      url: `/${PACKAGE_ADMINISTRATION}`,
      urlTo: `/${PRIVATE}/${PACKAGE_ADMINISTRATION}`,
    },
    createPackage: {
      url: `/${PACKAGE_ADMINISTRATION}/${CREATE}`,
      urlTo: `/${PRIVATE}/${PACKAGE_ADMINISTRATION}/${CREATE}`,
    },
    editPackage: {
      url: `/${PACKAGE_ADMINISTRATION}/${EDIT}/:packageId`,
      urlTo: `/${PRIVATE}/${PACKAGE_ADMINISTRATION}/${EDIT}`,
    },
    destinationAdministration: {
      url: `/${DESTINATION_ADMINISTRATION}`,
      urlTo: `/${PRIVATE}/${DESTINATION_ADMINISTRATION}`,
    },
    editDestination: {
      url: `/${DESTINATION_ADMINISTRATION}/${EDIT}/:destinationId`,
      urlTo: `/${PRIVATE}/${DESTINATION_ADMINISTRATION}/${EDIT}`,
    },
  },
}
