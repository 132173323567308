import {
  dataPrivacyPolicyText,
  eventsPromText,
  frequentQuestionsText,
  termsAndConditionsText,
  tripsPromText,
} from 'presentation/constant/texts/footer'
import { urlRoutes } from 'presentation/routes/config/urlRoutes/urlRoutes'

const travel = `${process.env.REACT_APP_URL_TRAVEL_FRONTOFFICE}`
const graduation = `${process.env.REACT_APP_URL_GRADUATION_FRONTOFFICE}`
export const promItems = [
  { id: 1, name: tripsPromText, url: travel },
  { id: 2, name: eventsPromText, url: graduation },
]

const frequentQuestionsUrl = `${process.env.REACT_APP_URL_FREQUENT_QUESTIONS_FORMAT}`
const termsAndConditionsUrl = `${process.env.REACT_APP_URL_TERMS_AND_CONDITIONS_FORMAT}`
export const footerLeftGridItems = [
  { id: 1, name: frequentQuestionsText, url: frequentQuestionsUrl },
  { id: 2, name: termsAndConditionsText, url: termsAndConditionsUrl },
]

export const footerRightGridItems = [
  {
    id: 1,
    name: dataPrivacyPolicyText,
    url: urlRoutes.public.footer.dataPrivacyPolicy.urlTo,
  },
]
