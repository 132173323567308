import { lazy } from 'react'

import { urlRoutes } from 'presentation/routes/config/urlRoutes/urlRoutes'

const PromotionAdministration = lazy(() => import('presentation/pages/promotionAdministration'))
const CreatePromotion = lazy(() => import('presentation/pages/createPromotion'))
const EditPromotion = lazy(() => import('presentation/pages/editPromotion'))

export const promotionAdministrationRoutes = [
	{
		nameUrl: 'Administración de promociones',
		path: urlRoutes.private.promotionAdministration.url,
		element: PromotionAdministration,
		rols: ['*']
	},
	{
		nameUrl: 'Creación de promoción',
		path: urlRoutes.private.createPromotion.url,
		element: CreatePromotion,
		rols: ['*']
	},
	{
		nameUrl: 'Edición de promoción',
		path: urlRoutes.private.editPromotion.url,
		element: EditPromotion,
		rols: ['*']
	}
]
