import { memo, ReactNode } from 'react'
import { Box, Toolbar } from '@mui/material'
import { useSelector } from 'react-redux'
import { authUserState } from 'presentation/redux/slices/authUserSlice'
import { Footer, Header, SideBar } from './components'
import './style.css'

type LayoutProps = {
  children: ReactNode
}

function Layout({ children }: LayoutProps) {
  const { logged } = useSelector(authUserState)

  return (
    <Box sx={{ display: 'flex', height: '100vh', flexDirection: 'column', scrollbarWidth: 'none' }}>
      <Header />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
        }}
      >
        <Toolbar />
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            alignItems: 'start',
            overflow: 'hidden',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              height: '100%',
              marginTop: '17px',
              maxWidth: '1700px',
              width: '100%',
            }}
          >
            {logged && <SideBar />}
            <Box sx={{ marginLeft: logged ? '360px' : 0 }}>{children}</Box>
          </Box>
        </Box>
      </Box>
      <Footer />
    </Box>
  )
}

export default memo(Layout)
