import IRepresentativeRepository from 'domain/interfaces/repositories/IAuthUserRepository'
import {
  AuthLoginRepresentativeRequest,
  AuthLoginRepresentativeResponse,
  LogoutRepresentativeCommandResponse,
  RecoverPasswordRepresentativeCommandResponse,
  RefreshTokenRepresentativeCommandResponse,
  ResetPasswordAuthUserCommandRequest,
  ResetPasswordAuthUserCommandResponse,
} from 'domain/entities/authUser'
import { sendRequest } from 'infraestructure/dataSources/http/axios'
import { AsyncApiResponse } from 'domain/models/apiResponse'
import {
  LOGIN_ENDPOINT,
  LOGOUT_ENDPOINT,
  RECOVER_PASSWORD_ENDPOINT,
  REFRESH_TOKEN_ENDPOINT,
  RESET_PASSWORD_ENDPOINT,
} from './endpoints.routes'

export default class AuthUserRepository implements IRepresentativeRepository {
  async recoverPassword(
    identificationNumber: string,
  ): AsyncApiResponse<RecoverPasswordRepresentativeCommandResponse> {
    const request = {
      post: {
        path: `${RECOVER_PASSWORD_ENDPOINT}/${identificationNumber}`,
        body: {},
      },
    }
    return await sendRequest<RecoverPasswordRepresentativeCommandResponse>(request)
  }

  async refreshToken(
    refreshToken: string,
  ): AsyncApiResponse<RefreshTokenRepresentativeCommandResponse> {
    const request = {
      post: {
        path: REFRESH_TOKEN_ENDPOINT,
        body: { refreshToken },
      },
    }
    return await sendRequest<RefreshTokenRepresentativeCommandResponse>(request)
  }

  async login(
    data: AuthLoginRepresentativeRequest,
  ): AsyncApiResponse<AuthLoginRepresentativeResponse> {
    const request = {
      post: {
        path: LOGIN_ENDPOINT,
        body: data,
      },
    }
    return await sendRequest<AuthLoginRepresentativeResponse>(request)
  }

  async logout(refreshToken: string): AsyncApiResponse<LogoutRepresentativeCommandResponse> {
    const request = {
      post: {
        path: LOGOUT_ENDPOINT,
        body: { refreshToken },
      },
    }
    return await sendRequest<LogoutRepresentativeCommandResponse>(request)
  }

  async resetPassword(
    data: ResetPasswordAuthUserCommandRequest,
  ): AsyncApiResponse<ResetPasswordAuthUserCommandResponse> {
    const request = {
      post: {
        path: `${RESET_PASSWORD_ENDPOINT}/${data?.token}`,
        body: data,
      },
    }
    return await sendRequest<ResetPasswordAuthUserCommandResponse>(request)
  }
}
