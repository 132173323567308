import { Box, IconButton } from '@mui/material'
import {
  AddBoxOutlined as AddBoxOutlinedIcon,
  CheckBoxOutlined as CheckBoxOutlinedIcon,
} from '@mui/icons-material'

import { InputApp } from 'presentation/styles/index.styled'
import { FormControlApp } from 'presentation/components/form/input'
import { Item } from 'domain/entities/package'

type PackageFormAddItemProps = {
  handleAddItem: () => void
  handleEditItem: () => void
  register: any
  label: string
  name: string
  element: Item
  isEmptyInput: boolean
}

export default function PackageFormAddItem({
  handleAddItem,
  handleEditItem,
  label,
  register,
  name,
  element,
  isEmptyInput,
}: PackageFormAddItemProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        gap: { xs: 0, md: '10px' },
        marginTop: '15px',
      }}
    >
      <FormControlApp label={label} isRequired>
        <InputApp rows={2} multiline fullWidth {...register(name)} />
      </FormControlApp>
      <IconButton
        onClick={element?.id ? handleEditItem : handleAddItem}
        disabled={!isEmptyInput}
        sx={{
          marginRight: '-12px',
          marginTop: { xs: '-20px', md: '12px' },
          alignSelf: { xs: 'flex-end', md: 'auto' },
        }}
      >
        {element?.id ? (
          <CheckBoxOutlinedIcon color="info" fontSize="large" />
        ) : (
          <AddBoxOutlinedIcon color="info" fontSize="large" />
        )}
      </IconButton>
    </Box>
  )
}
