import IPromotionRepository from 'domain/interfaces/repositories/IPromotionRepository'
import { UpdatePromotionCommandRequest } from 'domain/entities/promotion'

export const updatePromotionCommand = (
  repository: IPromotionRepository,
  promotionId: string,
  data: UpdatePromotionCommandRequest,
) => {
  return repository.updatePromotion(promotionId, data)
}
