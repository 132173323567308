import { useCallback, useEffect, useState } from 'react'

import { ImageController } from 'infraestructure/controllers/imageController'
import useError from 'presentation/hooks/useError'

type UseUploadImageProps = {
  imageUrl: string | undefined
  setValue: any
}
export const useUploadImage = ({ imageUrl, setValue }: UseUploadImageProps) => {
  const [image, setImage] = useState<string>(imageUrl || '')
  const [uploadImage, setUploadImage] = useState(null)
  const [uploadImageError, setUploadImageError] = useState<string>('')
  const [isLoadingUploadImage, setIsLoadingUploadImage] = useState<boolean>(false)
  const [errorResponseUploadImage, setErrorResponseUploadImage] = useState<string>('')
  const { getErrorMessage } = useError()
  const heightImage = 900
  const widthImage = 385

  const handleUploadImage = useCallback(
    async (selectedFile: any) => {
      setIsLoadingUploadImage(true)
      try {
        const formData = new FormData()
        formData.append('file', selectedFile)
        const response = await ImageController.uploadImage(formData)
        const imageData = response?.data
        if (imageData) {
          const img = new Image()
          img.src = imageData?.url
          img.onload = () => {
            const { height, width } = img
            if (height > heightImage + 10 && width > widthImage + 10) {
              setUploadImageError('La imagen excede las dimensiones (900px x 385px)')
            } else {
              setImage(imageData?.url)
              setValue('image', imageData?.url)
            }
          }
          setIsLoadingUploadImage(false)
        }
      } catch (error: any) {
        setErrorResponseUploadImage(getErrorMessage({ error }))
        setIsLoadingUploadImage(false)
      }
    },
    [getErrorMessage],
  )

  useEffect(() => {
    if (uploadImage) {
      handleUploadImage(uploadImage)
    }
  }, [uploadImage])

  const handleChangeImage = (event: any) => {
    setUploadImageError('')
    setUploadImage(null)
    const imageToUpload = event.target.files[0]
    const type = ['image/jpeg', 'image/png']
    if (imageToUpload) {
      if (type.includes(imageToUpload?.type)) {
        if (imageToUpload.size > 409600) {
          setUploadImageError('La imagen excede los 400kb')
        } else {
          setUploadImage(imageToUpload)
        }
      } else {
        setUploadImageError('No has subido una imagen válida')
      }
    } else {
      setUploadImageError('')
    }
  }

  const handleClearError = () => {
    setUploadImageError('')
    setErrorResponseUploadImage('')
  }

  return {
    uploadImageError,
    image,
    isLoadingUploadImage,
    handleChangeImage,
    errorResponseUploadImage,
    handleClearError,
  }
}
