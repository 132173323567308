import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { styled } from '@mui/material/styles'

export const StyledDatePicker = styled(DatePicker)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(2.8),
  },
  '& .MuiOutlinedInput-input': {
    padding: '9.375px 10px',
    textAlign: 'start',
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '5px',
    border: `1px solid ${theme.palette.info.light}`,
    color: theme.palette.primary.main,
    fontSize: '12px',
  },
  '& .MuiSvgIcon-root': {
    color: theme.palette.info.light,
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 0,
  },
  '&.Mui-selected': {
    color: '#FFFFFF',
    backgroundColor: '#124CFD',
  },
}))
