import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RepresentativeState } from 'domain/models/representative'
import { RootState } from 'presentation/redux/reducers'

const initialState: RepresentativeState = {
  isLoading: false,
  error: null,
}

export const representativeSlice = createSlice({
  name: 'representative',
  initialState,
  reducers: {
    deleteCommitteeStart: (state: RepresentativeState) => {
      state.isLoading = true
      state.error = null
    },
    deleteCommitteeSuccess: (state: RepresentativeState) => {
      state.isLoading = false
    },
    deleteCommitteeError: (state: RepresentativeState, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
    clearErrorCommittee: (state: RepresentativeState) => {
      state.error = null
    },
  },
})

export const representativeState = (state: RootState) => state.representative
export const {
  deleteCommitteeStart,
  deleteCommitteeSuccess,
  deleteCommitteeError,
  clearErrorCommittee,
} = representativeSlice.actions

export default representativeSlice.reducer
