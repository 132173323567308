import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { ThemeProvider } from '@mui/material/styles'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment from 'moment'
import 'moment/locale/es'

import { newTheme } from './theme'
import { StyledDatePicker } from './index.styled'

type InputDatePickerProps = {
  handleChange: (value: Date) => void
  minDate?: Date
  value: Date | string
  isDisabled?: boolean
}

function InputDatePicker({ handleChange, minDate, value, isDisabled }: InputDatePickerProps) {
  const handleChangeDate = (newValue: any) => {
    const valueDate = newValue.local().toDate()
    handleChange(valueDate)
  }

  return (
    <ThemeProvider theme={newTheme}>
      <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="es">
        <StyledDatePicker
          format="DD/MM/YYYY"
          minDate={minDate ? moment(minDate) : moment()}
          onChange={(newValue: any) => handleChangeDate(newValue)}
          value={moment(value)}
          disabled={isDisabled}
        />
      </LocalizationProvider>
    </ThemeProvider>
  )
}

export default InputDatePicker
