import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'

export const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '5px',
  maxHeight: '400px',
  height: '100%',
  overflow: 'auto',
  width: '100%',
  '::-webkit-scrollbar': {
    width: '8px',
    background: theme.mixins.scrollBar.color,
    borderRadius: '10px',
  },
  '::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
  },
  '::-webkit-scrollbar-thumb': {
    background: theme.palette.info.main,
    borderRadius: '10px',
  },
}))
