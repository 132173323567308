import { RecoverPasswordRepresentativeCommandResponse } from 'domain/entities/authUser'

export const UserEmpty = {
  exp: 0,
  fullName: '',
  iat: 0,
  user_id: '',
}

export const UserLocalStorageEmpty = {
  logged: false,
  refreshToken: '',
  representative: UserEmpty,
  refreshExpiresIn: '',
}

export type User = {
  exp: number
  fullName: string
  iat: number
  user_id: string
}

export type UserLocalStorage = {
  logged: boolean
  refreshToken: string
  user: User
  refreshExpiresIn: number
}

export type TokenLocalStorage = {
  accessToken: string
  accessExpiresIn: number
}

export const TokenLocalStorageEmpty = {
  accessToken: '',
  accessExpiresIn: '',
}

export type AuthUserState = {
  logged: boolean
  errorRefreshToken: string | null
  error: string | null
  user: User
  token: string
  refreshToken: string
  isLoading: boolean
  refreshExpiresIn: number
  accessExpiresIn: number
  userForgotPassword: RecoverPasswordRepresentativeCommandResponse | null
}

export type PayloadLoginOrRefreshTokenAction = {
  logged: boolean
  user: User
  token: string
  refreshToken: string
  refreshExpiresIn: number
  accessExpiresIn: number | undefined
}
