import { useLocation, useNavigate } from 'react-router-dom'
import { AppBar, Box, Toolbar, useTheme } from '@mui/material'

import { urlRoutes } from 'presentation/routes/config/urlRoutes/urlRoutes'
import LogoIcon from 'presentation/assets/logoHeader.svg'
import { useHeader } from './hooks/useHeader'
import { HeaderNavItems, HeaderSignInItem } from './components'

function Header() {
  const navigate = useNavigate()
  const theme = useTheme()
  const { logged } = useHeader()
  const location = useLocation()

  const handleRedirect = () => {
    if (!logged) {
      navigate(urlRoutes.public.auth.login.urlTo)
    }
  }

  return (
    <AppBar position="fixed" sx={{ borderBottom: `2px solid ${theme.palette.info.main}` }}>
      <Toolbar
        sx={{
          backgroundColor: 'primary',
          alignItems: 'center',
          alignSelf: 'center',
          padding: logged ? '13px 27px' : '15px 27px',
          minHeight: '80px',
          height: 'auto',
          maxWidth: '1700px',
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexGrow: 1,
            marginRight: '120px',
            alignItems: 'left',
            cursor: logged ? 'default' : 'pointer',
          }}
          onClick={handleRedirect}
        >
          <img alt="Logo" src={LogoIcon} />
        </Box>
        {logged ? <HeaderNavItems /> : !location.pathname.includes('login') && <HeaderSignInItem />}
      </Toolbar>
    </AppBar>
  )
}

export default Header
