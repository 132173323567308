export type DataFormRef = {
  packageId: string
  destination: string
  packageName: string
  description: string
  hotel: string
  numberPersonByIncludePrice: number
  maxCapacity: number
  nights: number
  days: number
  rating: number
  startDate: string
  endDate: string
  include: string[]
  notInclude: string[]
  observations: string[]
  price: number
  image: string
  img: string
}

export const DataRefEmpty = {
  packageId: '',
  destination: '',
  packageName: '',
  description: '',
  hotel: '',
  numberPersonByIncludePrice: 0,
  maxCapacity: 0,
  nights: 0,
  days: 0,
  rating: 0,
  startDate: '',
  endDate: '',
  include: [],
  notInclude: [],
  observations: [],
  price: 0,
  image: '',
}

export type Item = {
  id: string
  name: string
}

export const ItemEmpty = {
  id: '',
  name: '',
}
