import { ChangeEvent, memo, ReactNode, useState } from 'react'
import { Box } from '@mui/material'

import { Admin } from 'domain/entities/admin'
import { userEmpty } from 'domain/models/admin'
import { FormControlApp } from 'presentation/components/form/input'
import { InputApp, StyledLink } from 'presentation/styles/index.styled'
import { labels } from 'presentation/constant/labels'
import {
  AlertError,
  ButtonApp,
  LoadingBackdrop,
  PasswordInput,
} from 'presentation/components/elements'
import { urlRoutes } from 'presentation/routes/config/urlRoutes/urlRoutes'
import { backText, cancelText } from 'presentation/constant/globals'
import { validateEmailAddress } from 'presentation/utils/validateEmailAddress'
import { validatePassword } from 'presentation/utils/validatePassword'
import { validateInputString } from 'presentation/utils/validateInputString'
import { validateIdentificationNumber } from 'presentation/utils/validateIdentificationNumber'
import { validatePhone } from 'presentation/utils/validaetCellPhone'
import { StyleContainerInputsBox, StyleWrapperInputsBox } from './index.styled'

type AdminFormProps = {
  user?: Admin
  children?: ReactNode | ReactNode[]
  buttonText: string
  handleCloseBackdrop?: () => void
  handleSubmit: (data: any) => void
  isLoading: boolean
  error: string
}

function AdminForm({
  user = userEmpty,
  children,
  buttonText,
  handleCloseBackdrop,
  handleSubmit,
  isLoading,
  error,
}: AdminFormProps) {
  const [fullName, setFullName] = useState<string>(user?.fullName)
  const [city, setCity] = useState<string>(user?.city)
  const [identificationNumber, setIdentificationNumber] = useState<string>(
    user?.identificationNumber,
  )
  const [cellphone, setCellphone] = useState<string>(user?.cellphone)
  const [email, setEmail] = useState<string>(user?.email)
  const [password, setPassword] = useState<string>('')
  const [fullNameError, setFullNameError] = useState<string>('')
  const [cityError, setCityError] = useState<string>('')
  const [identificationNumberError, setIdentificationNumberError] = useState<string>('')
  const [cellphoneError, setCellphoneError] = useState<string>('')
  const [emailError, setEmailError] = useState<string>('')
  const [passwordError, setPasswordError] = useState<string>('')

  const handleChangeFullName = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setFullName(value)
    validateInputString(value, setFullNameError, setFullName, fullName)
  }
  const handleChangeCity = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setCity(value)
    validateInputString(value, setCityError, setCity, city)
  }
  const handleChangeIdentificationNumber = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setIdentificationNumber(value)
    validateIdentificationNumber(
      value,
      setIdentificationNumberError,
      setIdentificationNumber,
      identificationNumber,
    )
  }
  const handleChangeCellphone = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setCellphone(value)
    validatePhone(value, setCellphoneError, setCellphone, cellphone)
  }
  const handleChangeEmail = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setEmailError(validateEmailAddress(value))
    setEmail(value)
  }

  const handleChangePassword = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setPasswordError(validatePassword(value))
    setPassword(value)
  }

  const data = {
    email,
    fullName,
    identificationNumber,
    cellphone,
    city,
  }
  let newData: any
  if (user?.id) {
    if (password) {
      newData = { ...data, ...{ active: user?.active, password } }
    } else {
      newData = { ...data, ...{ active: user?.active } }
    }
  } else {
    newData = { ...data, password }
  }

  const isDisabledButton =
    !email ||
    !cellphone ||
    !identificationNumber ||
    !city ||
    !fullName ||
    !!passwordError ||
    !!emailError ||
    !!cellphoneError ||
    !!identificationNumberError ||
    !!cityError ||
    !!fullNameError

  return (
    <form onSubmit={() => handleSubmit(newData)}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '24px',
          maxWidth: '1087px',
          width: '100%',
        }}
      >
        {isLoading && <LoadingBackdrop />}
        <StyleContainerInputsBox>
          <StyleWrapperInputsBox>
            <FormControlApp
              label={`${labels.editPromotionAddCommitteePopup.nameText} y ${labels.editPromotionAddCommitteePopup.lastNameText}`}
              errorMessage={fullNameError}
            >
              <InputApp
                name="fullName"
                value={fullName}
                onChange={handleChangeFullName}
                error={!!fullNameError}
              />
            </FormControlApp>
          </StyleWrapperInputsBox>
          <StyleWrapperInputsBox>
            <FormControlApp
              label={labels.institutionForm.phoneNumberText}
              errorMessage={cellphoneError}
            >
              <InputApp
                name="cellphone"
                value={cellphone}
                onChange={handleChangeCellphone}
                error={!!cellphoneError}
              />
            </FormControlApp>
          </StyleWrapperInputsBox>
        </StyleContainerInputsBox>
        <StyleContainerInputsBox>
          <StyleWrapperInputsBox>
            <FormControlApp label={labels.institutionForm.cityText} errorMessage={cityError}>
              <InputApp name="city" value={city} onChange={handleChangeCity} error={!!cityError} />
            </FormControlApp>
          </StyleWrapperInputsBox>
          <StyleWrapperInputsBox>
            <FormControlApp
              label={labels.recoverPassword.identificationNumberText}
              errorMessage={identificationNumberError}
            >
              <InputApp
                name="identificationNumber"
                value={identificationNumber}
                onChange={handleChangeIdentificationNumber}
                error={!!identificationNumberError}
              />
            </FormControlApp>
          </StyleWrapperInputsBox>
        </StyleContainerInputsBox>
        <StyleContainerInputsBox>
          <StyleWrapperInputsBox>
            <FormControlApp label={labels.login.email} errorMessage={emailError}>
              <InputApp
                name="email"
                value={email}
                onChange={handleChangeEmail}
                error={!!emailError}
              />
            </FormControlApp>
          </StyleWrapperInputsBox>
          <StyleWrapperInputsBox>
            <FormControlApp label={labels.login.passwordText} errorMessage={passwordError}>
              <PasswordInput
                name="password"
                value={password}
                onChangeInput={handleChangePassword}
              />
            </FormControlApp>
          </StyleWrapperInputsBox>
        </StyleContainerInputsBox>
        {user && (
          <Box
            sx={{
              display: 'flex',
              gap: '27px',
              justifyContent: 'flex-end',
              flexWrap: 'wrap',
              marginBottom: '56px',
            }}
          >
            {children}
          </Box>
        )}
      </Box>
      {!!error && (
        <Box sx={{ margin: '10px 0' }}>
          <AlertError text={error} />
        </Box>
      )}
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '20px' }}>
        {user?.id ? (
          <Box sx={{ width: '200px' }}>
            <ButtonApp isOutlined isFullWidth onClick={handleCloseBackdrop}>
              {cancelText}
            </ButtonApp>
          </Box>
        ) : (
          <StyledLink to={urlRoutes.private.adminManagement.urlTo}>
            <Box sx={{ width: '200px' }}>
              <ButtonApp isOutlined isFullWidth>
                {backText}
              </ButtonApp>
            </Box>
          </StyledLink>
        )}
        <ButtonApp
          onClick={() => handleSubmit(newData)}
          isDisabled={user ? isDisabledButton : isDisabledButton && !password}
        >
          {buttonText}
        </ButtonApp>
      </Box>
    </form>
  )
}

export default memo(AdminForm)
