import IPromotionRepository from 'domain/interfaces/repositories/IPromotionRepository'
import { UpdatePromotionCommitteeCommandRequest } from 'domain/entities/promotion'

export const updatePromotionCommitteeCommand = (
  repository: IPromotionRepository,
  promotionId: string,
  representativeId: string,
  data: UpdatePromotionCommitteeCommandRequest,
) => {
  return repository.updatePromotionCommittee(promotionId, representativeId, data)
}
