import { QueryPagination } from 'domain/models/queryPagination'
import { Institution } from 'domain/entities/institution'

export const institutionEmpty = {
  id: '',
  name: '',
  ruc: '',
  city: '',
  email: '',
  telephone: '',
}

export type InstitutionState = {
  isLoading: boolean
  error: string | null
  institutions: Institution[]
  pagination: QueryPagination
  institution: Institution
}
