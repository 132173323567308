import { ChangeEventHandler } from 'react'
import { Box, Fade, Stack } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

import { TextSubTitle } from 'presentation/components/elements/typography'
import {
  actionToAllowActiveOrUnactiveText,
  activeText,
  unActiveText,
} from 'presentation/constant/globals'
import { AntSwitch, StyledTooltip } from './index.styled'

type CustomizedSwitchProps = {
  isChecked: boolean
  handleChangeSwitch: ChangeEventHandler
  name: string
  isShowIcon?: boolean
  isDisabled?: boolean
}

export default function CustomizedSwitch({
  isChecked,
  handleChangeSwitch,
  name,
  isShowIcon = true,
  isDisabled = false,
}: CustomizedSwitchProps) {
  return (
    <Box sx={{ display: 'flex', gap: '10px' }}>
      <Stack direction="row" spacing={1} alignItems="center">
        <TextSubTitle fontSize="32px" lineHeight="24px">
          {unActiveText}
        </TextSubTitle>
        <AntSwitch
          inputProps={{ 'aria-label': 'ant design' }}
          checked={isChecked}
          onChange={handleChangeSwitch}
          name={name}
          disabled={isDisabled}
        />
        <TextSubTitle fontSize="32px" lineHeight="24px">
          {activeText}
        </TextSubTitle>
      </Stack>
      {isShowIcon && (
        <StyledTooltip
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 600 }}
          title={actionToAllowActiveOrUnactiveText}
        >
          <InfoOutlinedIcon color="info" fontSize="small" />
        </StyledTooltip>
      )}
    </Box>
  )
}
