export type ErrorResponse = {
  status: number
  statusText: string
  content: any
  exception?: any
}

export type ApiResponse<T> = {
  error?: ErrorResponse
  data?: T
  status?: number
  headers?: any
}

export const Status = {
  success: 200,
  created: 201,
  unauthorized: 401,
  conflict: 409,
}
export type AsyncApiResponse<T> = Promise<ApiResponse<T>>
