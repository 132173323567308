import { SyntheticEvent, useState } from 'react'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import { Box, Typography, useTheme } from '@mui/material'

import useSessionStorage from 'presentation/hooks/useSessionStorage'
import { selectedPanelKey } from 'presentation/constant/sessionStorageKeys'
import { StyledAccordion, StyledAccordionSummary, StyledLink } from './SideBar.styled'
import { sideBarElements } from './SideBar.constants'

export default function SideBar() {
  const selectedPanelInStorage = sessionStorage.getItem(selectedPanelKey)
  const [valueAccordion, setValueAccordion] = useState<string>(
    (selectedPanelInStorage as string) || 'panel1',
  )
  const [expanded, setExpanded] = useState<string | false>(
    (selectedPanelInStorage as string) || 'panel1',
  )
  const { saveDataAtSessionStorage } = useSessionStorage(selectedPanelKey)
  const theme = useTheme()

  const handleChange = (panel: string) => (event: SyntheticEvent, newExpanded: boolean) => {
    if (valueAccordion !== panel) {
      setValueAccordion(panel)
      setExpanded(newExpanded ? panel : false)
      saveDataAtSessionStorage({ data: panel })
    }
  }
  const border = `1.3px solid ${theme.palette.primary.main}30`

  return (
    <Box
      sx={{
        width: '360px',
        borderRight: border,
        borderLeft: border,
        height: '100%',
        position: 'fixed',
        marginTop: '4px',
      }}
    >
      {sideBarElements.map(({ id, route, expandedName, ariaControls, panelId, name }) => (
        <StyledLink to={route} key={id}>
          <StyledAccordion
            expanded={expanded === expandedName}
            disableGutters
            elevation={0}
            onChange={handleChange(expandedName)}
          >
            <StyledAccordionSummary
              aria-controls={ariaControls}
              id={panelId}
              expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
            >
              <Typography>{name}</Typography>
            </StyledAccordionSummary>
          </StyledAccordion>
        </StyledLink>
      ))}
    </Box>
  )
}
