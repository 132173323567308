import { QueryPagination } from 'domain/models/queryPagination'
import { Package } from 'domain/entities/pacakge'
import { ErrorResponse } from 'domain/models/apiResponse'

export type ErrorUpload = {
  error: ErrorResponse
}

export type PackageState = {
  isLoading: boolean
  error: string | null
  deleteError: string | null
  uploadError: string | null | ErrorUpload
  packagesList: Package[]
  package: Package
  pagination: QueryPagination
}

export const PackageEmpty = {
  id: '',
  packageId: '',
  hotel: '',
  persons: 0,
  maxCapacity: 0,
  days: 0,
  nights: 0,
  hotelStars: 0,
  startDate: '',
  endDate: '',
  include: [],
  notInclude: [],
  conditionsAndObservations: [],
  value: 0,
  imageUrl: '',
  destinationName: '',
  name: '',
  description: '',
}
