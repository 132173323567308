import { useCallback, useState } from 'react'

const useSessionStorage = (key: string) => {
  const [data, setData] = useState<any>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<any>('')

  const saveDataAtSessionStorage = ({ data: newData }: any) => {
    try {
      sessionStorage.setItem(key, newData)
      setLoading(false)
    } catch (err: any) {
      setError(err)
      setLoading(false)
    }
  }

  const getSessionStorageData = useCallback(() => {
    try {
      const element: any = sessionStorage.getItem(key)
      setData(JSON.parse(element))
      setLoading(false)
    } catch (err: any) {
      setError(err)
      setLoading(false)
    }
  }, [])

  const removeSessionStorageData = () => {
    try {
      sessionStorage.removeItem(key)
      setData(null)
      setLoading(false)
    } catch (err: any) {
      setError(err)
      setLoading(false)
    }
  }

  return {
    getSessionStorageData,
    saveDataAtSessionStorage,
    removeSessionStorageData,
    data,
    loading,
    error,
  }
}

export default useSessionStorage
