import React, { forwardRef } from 'react'
import { createTheme } from '@mui/material/styles'
import Red from '@mui/material/colors/red'
import { LinkProps } from '@mui/material/Link'
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

const LinkBehavior = forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }
>((props, ref) => {
  const { href, ...other } = props
  // Map href (MUI) -> to (react-router)
  return <RouterLink ref={ref} to={href} {...other} />
})

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    primary: {
      main: '#000000',
      light: '#151414',
      dark: '#000000',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#4A4A4A',
      light: '#898C90',
      dark: '#F0F3FF',
      contrastText: '#FFFFFF',
    },
    success: {
      main: '#43B02A',
      light: '#D0ECC9',
      dark: '#2C6424',
      contrastText: '#FFFFFF',
    },
    info: {
      main: '#124CFD',
      light: '#4C71FC',
      dark: '#124CFD',
      contrastText: '#FFFFFF',
    },
    warning: {
      main: '#FFCA40',
      light: '#FFEEBF',
      dark: '#8A670F',
      contrastText: '#FFFFFF',
    },
    error: {
      main: '#E40046',
      light: '#FDE6E5',
      dark: '#D11203',
      contrastText: '#FFFFFF',
    },
    background: { default: '#ffffff', paper: '#F2F5FF' },
    text: { primary: '#000000', secondary: '#ffffff', disabled: '#A2A7BA' },
  },

  typography: {
    fontFamily: ['"Roboto"'].join(','),
  },

  mixins: {
    grey: { color: '#6D7278' },
    secondaryZircon: { color: '#F0F3FF' },
    greyDark: { color: '#5F5F5F' },
    greyLight: { color: '#EDF1FF' },
    scrollBar: { color: '#D9D9D9' },
    greyPrimary: { color: '#D9D9D6' },
    nickel: { color: 'rgba(109, 114, 120, 1)' },
    workSans: { color: '#4D4D4D' },
    carrotOrange: { color: '#F79620' },
    rating: { color: '#FFE600' },
  },

  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: '#000000',
          boxShadow: 'none',
          borderBottom: '2px solid #124CFD',
        },
      },
    },
    MuiLink: {
      defaultProps: {
        color: 'primary',
        component: LinkBehavior,
      } as LinkProps,
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          background: '#000000',
          color: '#ffffff',
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        sx: {
          fontSize: '20px',
          color: '#000',
          fontWeight: '500',
          overflow: 'unset',
          '& span': {
            color: Red[500],
          },
        },
      },
    },
    MuiFormLabel: {
      defaultProps: {
        sx: {
          color: '#000',
          fontWeight: '500',
          fontSize: '15px',
          '& span': {
            color: Red[500],
          },
        },
      },
    },
  },
})
