import { ChangeEvent, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import dayjs from 'dayjs'

import { PromotionData } from 'domain/models/promotion'
import { useCreatePromotion, useUpdatePromotion } from 'presentation/redux/hooks/promotion'
import { labels } from 'presentation/constant/labels'
import { validateOnlyNumber } from 'presentation/utils/format'
import { LIST_PROMOTIONS, urlRoutes } from 'presentation/routes/config/urlRoutes/urlRoutes'
import { validateFields } from 'presentation/utils/validateFields'
import { validateRuc } from 'presentation/utils/validateRuc'
import { validateEmailAddress } from 'presentation/utils/validateEmailAddress'
import { validateInputString } from 'presentation/utils/validateInputString'

type IUsePromotionFormProps = {
  promotion?: PromotionData
  isEditPage: boolean
  rucInstitution: string
}

export const usePromotionForm = ({
  promotion,
  isEditPage,
  rucInstitution,
}: IUsePromotionFormProps) => {
  const [year, setYear] = useState<string>('')
  const [code, setCode] = useState<string>('')
  const [accountNumber, setAccountNumber] = useState<string>('')
  const [ruc, setRuc] = useState<string>('')
  const [businessName, setBusinessName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [yearError, setYearError] = useState<string>('')
  const [codeError, setCodeError] = useState<string>('')
  const [accountNumberError, setAccountNumberError] = useState<string>('')
  const [rucError, setRucError] = useState<string>('')
  const [businessNameError, setBusinessNameError] = useState<string>('')
  const [emailError, setEmailError] = useState<string>('')
  const navigate = useNavigate()
  const params = useParams()
  const { createPromotion, errorCreatePromotion } = useCreatePromotion()
  const { updatePromotion, errorUpdatePromotion } = useUpdatePromotion()
  const institutionId = String(params.institutionId)
  const promotionId = String(params.promotionId)

  useEffect(() => {
    if (promotion?.code) {
      setCode(promotion?.code)
    } else if (rucInstitution && year) {
      setCode(`${rucInstitution}-${year}`)
    }
  }, [rucInstitution, year, promotion?.code])

  useEffect(() => {
    if (promotion) {
      setYear(promotion?.year)
      setAccountNumber(promotion?.accountNumber)
      setRuc(promotion?.ruc)
      setBusinessName(promotion?.businessName)
      setEmail(promotion?.email)
    }
  }, [promotion])

  const handleChangeYearPromotion = (oldValue: any, event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setYear(value)
    if (value === '') {
      setYearError(labels.form.requiredFieldText)
    } else if (!validateOnlyNumber(value)) {
      setYear(oldValue)
    } else if (value.length < 4) {
      setYearError(labels.form.minCharactersYearPromotionText)
    } else if (value.length === 4) {
      if (value < dayjs().format('YYYY')) {
        setYear(oldValue)
      } else {
        setYearError('')
      }
    } else {
      setYearError('')
    }
  }
  const handleChangCodePromotion = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setCode(value)
    setCodeError(validateFields(value))
  }
  const handleChangeAccountNumber = (oldValue: any, event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setAccountNumber(value)
    if (value === '') {
      setAccountNumberError(labels.form.requiredFieldText)
    } else if (!validateOnlyNumber(value)) {
      setAccountNumber(oldValue)
    } else {
      setAccountNumberError('')
    }
  }
  const handleChangeRuc = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setRuc(value)
    validateRuc(value, setRucError, setRuc, ruc)
  }
  const handleChangeBusinessName = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setBusinessName(value)
    validateInputString(value, setBusinessNameError, setBusinessName, businessName)
  }
  const handleChangeEmail = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setEmail(value)
    setEmailError(validateEmailAddress(value))
  }
  const listPromotionsUrl = `${urlRoutes.private.institutionAdministration.urlTo}/${institutionId}/${LIST_PROMOTIONS}`

  const handleCreateOrUpdateInstitution = async () => {
    const data = {
      ruc,
      businessName,
      email,
      code,
      accountNumber,
    }
    const dataCreate = {
      ...data,
      ...{ year: Number(year) },
    }
    const dataUpdate = {
      ...data,
      ...{ active: true },
    }
    const response = isEditPage
      ? await updatePromotion(promotionId, dataUpdate)
      : await createPromotion(institutionId, dataCreate)
    if (response) {
      navigate(listPromotionsUrl)
    }
  }

  const isDisabledButton =
    !code ||
    !year ||
    !email ||
    !accountNumber ||
    !ruc ||
    !businessName ||
    !!yearError ||
    !!emailError ||
    !!accountNumberError ||
    !!codeError ||
    !!rucError ||
    !!businessNameError

  return {
    isDisabledButton,
    handleCreateOrUpdateInstitution,
    handleChangeEmail,
    handleChangeBusinessName,
    handleChangCodePromotion,
    handleChangeRuc,
    handleChangeAccountNumber,
    handleChangeYearPromotion,
    errorCreatePromotion,
    errorUpdatePromotion,
    yearError,
    year,
    ruc,
    rucError,
    emailError,
    email,
    accountNumber,
    accountNumberError,
    listPromotionsUrl,
    code,
    codeError,
    businessName,
    businessNameError,
  }
}
