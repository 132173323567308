export const CORE_API_BASE = `${process.env.REACT_APP_CORE_API_HOST}`
const ADMIN = 'admin'
const AUTH = 'auth'
const PASSWORDS = 'passwords'
const INSTITUTIONS = 'institutions'
const TRAVEL_PACKAGES = 'travel-packages'
export const PROMOTIONS = 'promotions'
export const CREATE = 'create'
export const UPDATE = 'update'
export const COMMITTEE = 'committee'
export const REPRESENTATIVES = 'representatives'
export const TYPES = 'types'

export const LOGIN_ENDPOINT = `${CORE_API_BASE}/${ADMIN}/${AUTH}/login`
export const LOGOUT_ENDPOINT = `${CORE_API_BASE}/${ADMIN}/${AUTH}/logout`
export const REFRESH_TOKEN_ENDPOINT = `${CORE_API_BASE}/${ADMIN}/${AUTH}/refresh-token`
export const RECOVER_PASSWORD_ENDPOINT = `${CORE_API_BASE}/${ADMIN}/${AUTH}/${PASSWORDS}/forgot`
export const RESET_PASSWORD_ENDPOINT = `${CORE_API_BASE}/${ADMIN}s/${PASSWORDS}/reset`

export const GET_ALL_INSTITUTIONS_ENDPOINT = `${CORE_API_BASE}/${INSTITUTIONS}`
export const CREATE_INSTITUTION_ENDPOINT = `${CORE_API_BASE}/${INSTITUTIONS}/${CREATE}`

export const GET_ALL_ADMINS_ENDPOINT = `${CORE_API_BASE}/admins`
export const GET_ALL_FILES_ENDPOINT = `${CORE_API_BASE}/files`
export const GET_ALL_NOTIFICATIONS_ENDPOINT = `${CORE_API_BASE}/${ADMIN}/notifications`
export const UPLOAD_IMAGE_ENDPOINT = `${CORE_API_BASE}/${TRAVEL_PACKAGES}/upload-image`
export const UPLOAD_PACKAGES_ENDPOINT = `${CORE_API_BASE}/${TRAVEL_PACKAGES}/upload`

export const GET_ALL_PACKAGES_ENDPOINT = `${CORE_API_BASE}/${TRAVEL_PACKAGES}`
export const GET_ALL_DESTINAIONS_ENDPOINT = `${CORE_API_BASE}/destinations`
