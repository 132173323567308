import { QueryPagination } from 'domain/models/queryPagination'
import { Promotion, ReadGetPromotionQueryResponse } from 'domain/entities/promotion'

export type Params = {
  promotionId: string
  institutionId: string
}
export const PromotionEmpty = {
  accountNumber: '',
  active: false,
  businessName: '',
  code: '',
  committeeMembers: [],
  email: '',
  promotionId: '',
  ruc: '',
  year: '',
}

export type PromotionState = {
  isLoading: boolean
  error: string | null
  errorCreatePromotion: string | null
  errorUpdatePromotion: string | null
  errorCreateCommittee: string | null
  errorUpdateCommittee: string | null
  errorSetDelegate: string | null
  promotions: Promotion[]
  promotion: ReadGetPromotionQueryResponse
  pagination: QueryPagination
}

export type PromotionData = {
  code: string
  year: string
  accountNumber: string
  ruc: string
  businessName: string
  email: string
}
