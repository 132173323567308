import { useState } from 'react'
import { Backdrop, CircularProgress } from '@mui/material'

export default function LoadingBackdrop() {
  const [open, setOpen] = useState(true)
  const handleClose = () => setOpen(!open)

  return (
    <Backdrop sx={{ color: '#fff', zIndex: 3 }} open={open} onClick={handleClose}>
      <CircularProgress color="info" size={90} />
    </Backdrop>
  )
}
