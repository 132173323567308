import { Box, Fade } from '@mui/material'
import { Link } from 'react-router-dom'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

import { PromotionData } from 'domain/models/promotion'
import { InputApp } from 'presentation/styles/index.styled'
import { FormControlApp } from 'presentation/components/form/input'
import {
  AlertError,
  ButtonApp,
  TextBold,
  TextParagraph,
  TextSubTitle,
} from 'presentation/components/elements'
import {
  bankAccountText,
  dataForBankTransferText,
} from 'presentation/constant/texts/promotionAdministration'
import { labels } from 'presentation/constant/labels'
import { cancelText } from 'presentation/constant/globals'
import { StyleContainerInputsBox, StyledTooltips, StyleWrapperInputsBox } from './index.styled'
import { usePromotionForm } from './hooks'

type PromotionFormProps = {
  promotion?: PromotionData
  isEditPage?: boolean
  submitButtonText: string
  rucInstitution: string
}

export default function PromotionForm({
  promotion,
  submitButtonText,
  rucInstitution,
  isEditPage = false,
}: PromotionFormProps) {
  const {
    handleChangeBusinessName,
    handleChangeYearPromotion,
    errorCreatePromotion,
    errorUpdatePromotion,
    handleChangCodePromotion,
    handleChangeRuc,
    handleChangeEmail,
    handleChangeAccountNumber,
    handleCreateOrUpdateInstitution,
    isDisabledButton,
    ruc,
    rucError,
    yearError,
    year,
    emailError,
    email,
    accountNumberError,
    accountNumber,
    listPromotionsUrl,
    code,
    codeError,
    businessNameError,
    businessName,
  } = usePromotionForm({ promotion, isEditPage, rucInstitution })

  return (
    <form onSubmit={handleCreateOrUpdateInstitution}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          maxWidth: '1103px',
          width: '100%',
        }}
      >
        <StyleContainerInputsBox>
          <StyleWrapperInputsBox>
            {isEditPage ? (
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                <TextParagraph fontWeight={300} fontSize="32px" lineHeight="30px">
                  {labels.promotionForm.promotionText}
                </TextParagraph>
                <TextSubTitle fontSize="32px" lineHeight="30px">
                  {year}
                </TextSubTitle>
              </Box>
            ) : (
              <FormControlApp label={labels.promotionForm.promotionText} errorMessage={yearError}>
                <InputApp
                  name="year"
                  value={year}
                  onChange={(event: any) => handleChangeYearPromotion(year, event)}
                  error={!!yearError}
                />
              </FormControlApp>
            )}
          </StyleWrapperInputsBox>
          <StyleWrapperInputsBox>
            <FormControlApp label={labels.promotionForm.codePromotionText} errorMessage={codeError}>
              <InputApp
                name="code"
                value={code}
                onChange={handleChangCodePromotion}
                error={!!codeError}
              />
            </FormControlApp>
          </StyleWrapperInputsBox>
        </StyleContainerInputsBox>
        <Box sx={{ margin: '60px 0 25px 0', display: 'flex', gap: '11px' }}>
          <TextBold color="info.main">{bankAccountText.toUpperCase()}</TextBold>
          <StyledTooltips
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 600 }}
            placement="top"
            title={dataForBankTransferText}
          >
            <InfoOutlinedIcon color="info" />
          </StyledTooltips>
        </Box>
        <StyleContainerInputsBox>
          <StyleWrapperInputsBox>
            <FormControlApp
              label={labels.promotionForm.numberAccountText}
              errorMessage={accountNumberError}
            >
              <InputApp
                name="accountNumber"
                value={accountNumber}
                onChange={(event: any) => handleChangeAccountNumber(accountNumber, event)}
                error={!!accountNumberError}
              />
            </FormControlApp>
          </StyleWrapperInputsBox>
          <StyleWrapperInputsBox>
            <FormControlApp
              label={labels.promotionForm.socialReasonText}
              errorMessage={businessNameError}
            >
              <InputApp
                name="businessName"
                value={businessName}
                onChange={handleChangeBusinessName}
                error={!!businessNameError}
              />
            </FormControlApp>
          </StyleWrapperInputsBox>
        </StyleContainerInputsBox>
        <StyleContainerInputsBox>
          <StyleWrapperInputsBox>
            <FormControlApp label={labels.promotionForm.rucText} errorMessage={rucError}>
              <InputApp name="ruc" value={ruc} onChange={handleChangeRuc} error={!!rucError} />
            </FormControlApp>
          </StyleWrapperInputsBox>
          <StyleWrapperInputsBox>
            <FormControlApp label={labels.login.email} errorMessage={emailError}>
              <InputApp
                name="email"
                value={email}
                onChange={handleChangeEmail}
                error={!!emailError}
              />
            </FormControlApp>
          </StyleWrapperInputsBox>
        </StyleContainerInputsBox>
      </Box>
      {(!!errorCreatePromotion || !!errorUpdatePromotion) && (
        <Box sx={{ margin: '10px 0 10px 64px' }}>
          <AlertError text={errorCreatePromotion || errorUpdatePromotion} />
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          gap: '20px',
          marginTop: '24px',
        }}
      >
        <Link to={listPromotionsUrl} style={{ textDecoration: 'none' }}>
          <Box sx={{ width: '220px' }}>
            <ButtonApp isOutlined isFullWidth>
              {cancelText}
            </ButtonApp>
          </Box>
        </Link>
        <ButtonApp onClick={handleCreateOrUpdateInstitution} isDisabled={isDisabledButton}>
          {submitButtonText}
        </ButtonApp>
      </Box>
    </form>
  )
}
