import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { PaginationEmpty } from 'domain/models/queryPagination'
import { FileState } from 'domain/models/file'
import { ReadFilesQueryResponse } from 'domain/entities/file'
import { RootState } from 'presentation/redux/reducers'

const initialState: FileState = {
  isLoading: false,
  error: null,
  errorUpload: null,
  files: [],
  pagination: PaginationEmpty,
}

export const fileSlice = createSlice({
  name: 'file',
  initialState,
  reducers: {
    getAllFilesStart: (state: FileState) => {
      state.isLoading = true
      state.error = null
      state.errorUpload = null
    },
    getAllFilesSuccess: (state: FileState, action: PayloadAction<ReadFilesQueryResponse>) => {
      state.isLoading = false
      state.files = action.payload.entities
      state.pagination = action.payload.pagination
    },
    getAllFilesError: (state: FileState, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
    updateFileStart: (state: FileState) => {
      state.isLoading = true
      state.error = null
      state.errorUpload = null
    },
    updateFileSuccess: (state: FileState) => {
      state.isLoading = false
    },
    updateFileError: (state: FileState, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
    uploadFileStart: (state: FileState) => {
      state.isLoading = true
      state.error = null
      state.errorUpload = null
    },
    uploadFileSuccess: (state: FileState) => {
      state.isLoading = false
    },
    uploadFileError: (state: FileState, action: PayloadAction<string>) => {
      state.isLoading = false
      state.errorUpload = action.payload
    },
    deleteFileStart: (state: FileState) => {
      state.isLoading = true
      state.error = null
      state.errorUpload = null
    },
    deleteFileSuccess: (state: FileState) => {
      state.isLoading = false
    },
    deleteFileError: (state: FileState, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
  },
})

export const fileState = (state: RootState) => state.file
export const {
  getAllFilesStart,
  getAllFilesSuccess,
  getAllFilesError,
  uploadFileError,
  uploadFileSuccess,
  deleteFileSuccess,
  deleteFileStart,
  updateFileError,
  updateFileStart,
  updateFileSuccess,
  uploadFileStart,
  deleteFileError,
} = fileSlice.actions

export default fileSlice.reducer
