import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { AuthUserController } from 'infraestructure/controllers/authUserController'
import { Status } from 'domain/models/apiResponse'
import {
  authUserState,
  logoutError,
  logoutStart,
  logoutSuccess,
} from 'presentation/redux/slices/authUserSlice'
import { urlRoutes } from 'presentation/routes/config/urlRoutes/urlRoutes'
import useError from 'presentation/hooks/useError'

export const useLogout = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { isLoading, error } = useSelector(authUserState)
  const { getErrorMessage } = useError()
  const logout = async (token: string) => {
    dispatch(logoutStart())
    try {
      const response = await AuthUserController.logout(token)
      if (response?.status === Status.success) {
        sessionStorage.clear()
        dispatch(logoutSuccess())
        navigate(urlRoutes.public.auth.login.urlTo)
      }
    } catch (err) {
      dispatch(logoutError(getErrorMessage({ error: err })))
    }
  }
  return { logout, isLoading, error }
}
