import { Suspense } from 'react'
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { ErrorBoundary } from 'react-error-boundary'

import PublicPages from 'presentation/routes/publicPages/publicPages'
import PrivatePages from 'presentation/routes/privatePages/privatePages'
import { urlRoutes } from 'presentation/routes/config/urlRoutes/urlRoutes'
import { authUserState } from 'presentation/redux/slices/authUserSlice'
import { ErrorFallback, LoadingBackdrop } from 'presentation/components/elements'
import Layout from 'presentation/components/layout'
import PrivateRoutes from './privateRoutes'

export default function Index() {
  const { logged } = useSelector(authUserState)

  return (
    <Router>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Layout>
          <Suspense fallback={<LoadingBackdrop />}>
            <Routes>
              <Route
                path="/"
                element={
                  <Navigate
                    to={
                      logged
                        ? urlRoutes.private.institutionAdministration.urlTo
                        : urlRoutes.public.auth.login.urlTo
                    }
                    replace
                  />
                }
              />
              <Route path={urlRoutes.public.auth.url} element={<PublicPages />} />
              <Route path={urlRoutes.public.footer.url} element={<PublicPages />} />
              <Route element={<PrivateRoutes isLoggedIn={logged} />}>
                <Route path={urlRoutes.private.url} element={<PrivatePages rol="Admin" />} />
              </Route>
            </Routes>
          </Suspense>
        </Layout>
      </ErrorBoundary>
    </Router>
  )
}
