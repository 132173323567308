import IPromotionRepository from 'domain/interfaces/repositories/IPromotionRepository'
import { CreatePromotionCommandRequest } from 'domain/entities/promotion'

export const createPromotionCommand = (
  repository: IPromotionRepository,
  institutionId: string,
  data: CreatePromotionCommandRequest,
) => {
  return repository.createPromotion(institutionId, data)
}
