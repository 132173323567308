import { lazy } from 'react'

import { urlRoutes } from 'presentation/routes/config/urlRoutes/urlRoutes'

const DocumentManagement = lazy(() => import('presentation/pages/documentManagement'))

export const documentManagementRoutes = [
	{
		nameUrl: 'Administración de documentos',
		path: urlRoutes.private.documentManagement.url,
		element: DocumentManagement,
		rols: ['*'],
	},
]
