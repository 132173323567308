import { styled } from '@mui/material/styles'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import {
  InputBase,
  InputLabel,
  Paper,
  Rating,
  TableContainer,
  TablePagination,
  TableRow,
} from '@mui/material'
import { Link } from 'react-router-dom'

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.info.main,
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '24px',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: '12px',
    fontWeight: 300,
    lineHeight: '24px',
    color: theme.palette.primary.main,
    borderRight: `1px solid ${theme.palette.primary.main}20`,
    padding: '10px 16px',
    '&:last-child td': {
      border: 0,
    },
  },
  border: 0,
}))

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.mixins.greyLight.color,
  },
  border: 0,
  // hide last border
  '&:last-child td, &:last-child th': {
    // border: 0,
  },
}))

export const StyledTablePaper = styled(Paper)(({ theme }) => ({
  boxShadow: 'none',
  background: theme.palette.background.default,
}))

export const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  backgroundColor: 'white',
  overflow: 'hidden',
  '::-webkit-scrollbar': {
    width: '6px',
    background: theme.mixins.scrollBar.color,
    borderRadius: '5px',
    height: '105px',
  },
  '::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
  },
  '::-webkit-scrollbar-thumb': {
    background: theme.palette.info.main,
    borderRadius: '10px',
    height: 'auto',
  },
}))

export const StyledTablePagination = styled(TablePagination)(({ theme }) => ({
  background: theme.palette.background.default,
  borderBottom: 0,
  display: 'flex',
  justifyContent: 'flex-end',
  marginRight: '48px',
}))

export const StyledRating = styled(Rating)(({ theme }) => ({
  '& .MuiRating-iconFilled': {
    color: theme.mixins.rating.color,
  },
  '& .MuiRating-iconHover': {
    color: theme.mixins.rating.color,
  },
  '& .MuiRating-iconEmpty': {
    color: theme.mixins.rating.color,
  },
  '&.MuiRating-root.Mui-disabled': {
    opacity: '0.9',
  },
}))

export const InputApp = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(2.4),
  },
  '& .MuiInputBase-input': {
    borderRadius: '5px',
    position: 'relative',
    backgroundColor: theme.palette.background.default,
    border: `1px solid ${theme.palette.info.light}`,
    color: theme.palette.secondary.light,
    fontSize: '12px',
    width: '100%',
    padding: '9.375px 10px',
    transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
    '&:focus': {
      border: `1px solid ${theme.palette.info.light}`,
    },
    '&:disabled': {
      backgroundColor: theme.palette.background.paper,
    },
  },
}))

export const InputLabelApp = styled(InputLabel)(({ theme }) => ({
  '&.MuiInputLabel-root': {
    color: theme.palette.secondary.main,
    fontSize: '16px',
    lineHeight: '18px',
    fontWeight: 400,
  },
}))

export const StyledLink = styled(Link)(() => ({
  textDecoration: 'none',
}))
