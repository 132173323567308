import { labels } from 'presentation/constant/labels'
import { validatePass } from 'presentation/utils/format'

export const validatePassword = (password: string) => {
  if (password === '') {
    return labels.form.requiredFieldText
  }
  if (password.length < 8) {
    return labels.form.minCharactersText
  }
  if (!validatePass(password)) {
    return labels.form.wrongPasswordsText
  }
  if (password.length > 20) {
    return labels.form.maxCharactersPasswordsText
  }
  return ''
}
