import { styled } from '@mui/material/styles'
import { Divider, TablePagination, Typography } from '@mui/material'

export const StyledItemMenuText = styled(Typography)(() => ({
  fontSize: '16px',
}))

export const StyledDivider = styled(Divider)(({ theme }) => ({
  border: `1px solid ${theme.palette.background.default}`,
}))

export const StyledPagination = styled(TablePagination)(({ theme }) => ({
  background: theme.palette.primary.main,
  borderBottom: 0,
  display: 'flex',
  justifyContent: 'flex-end',
  marginRight: '48px',
  color: theme.palette.text.secondary,
  '& .Mui-disabled': {
    color: `${theme.mixins.grey.color} !important`,
  },
  '&.MuiTableCell-root': {
    marginRight: 0,
  },
}))
