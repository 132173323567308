import { memo } from 'react'
import { Box } from '@mui/material'

import Logo from 'presentation/assets/logo.svg'
import {
  ButtonTextApp,
  GridContainer,
  GridItem,
  TextParagraph,
} from 'presentation/components/elements'
import { copyRightText, reservedRightsRText, siteMapText } from 'presentation/constant/texts/footer'
import {
  ContainerBox,
  StyledLink,
  StyledLinkTermsConditionsText,
  StyledLinkText,
} from './footer.styled'
import { footerLeftGridItems, footerRightGridItems, promItems } from './footer.constants'

function Footer() {
  return (
    <ContainerBox>
      <Box
        sx={{
          padding: { lg: '33px 80px', md: '33px 50px', xs: '20px 10px' },
          backgroundColor: 'primary.main',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            maxWidth: '1700px',
            width: '100%',
          }}
        >
          <GridContainer alignItems="center" justifyContent={{ xs: 'center' }}>
            <GridItem lg={2} md={2} sm={2} xs={12}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: { md: 'start', sm: 'center', xs: 'center' },
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start',
                    gap: '14px',
                  }}
                >
                  <TextParagraph
                    color="text.secondary"
                    fontSize="15px"
                    lineHeight="24px"
                    fontWeight={700}
                  >
                    {siteMapText}
                  </TextParagraph>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '5px',
                    }}
                  >
                    {promItems.map(({ id, name, url }) => (
                      <StyledLink key={id} to={url}>
                        <Box color="secondary">
                          <StyledLinkText>{name}</StyledLinkText>
                        </Box>
                      </StyledLink>
                    ))}
                  </Box>
                </Box>
              </Box>
            </GridItem>
            <GridItem lg={6} md={10} sm={10} xs={12}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: { xs: 'center' },
                  width: '100%',
                  marginTop: { md: '35px' },
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    gap: { lg: '120px', md: '20x', xs: '10px' },
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'start',
                    }}
                  >
                    {footerLeftGridItems.map(({ id, name, url }) => (
                      <ButtonTextApp key={id} onClick={() => window.open(url)} padding="2px 8px">
                        <StyledLinkText sx={{ textDecoration: 'underline' }}>{name}</StyledLinkText>
                      </ButtonTextApp>
                    ))}
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'start',
                    }}
                  >
                    {footerRightGridItems.map(({ id, name, url }) => (
                      <ButtonTextApp key={id} onClick={() => window.open(url)}>
                        <StyledLinkText sx={{ textDecoration: 'underline' }}>{name}</StyledLinkText>
                      </ButtonTextApp>
                    ))}
                  </Box>
                </Box>
              </Box>
            </GridItem>
            <GridItem lg={4} md={12}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '15px',
                  width: '100%',
                }}
              >
                <Box>
                  <img alt={Logo} src={Logo} />
                </Box>
              </Box>
            </GridItem>
          </GridContainer>
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: 'background.paper',
          color: 'primary.main',
          padding: { xs: '7.5px 10px', md: '16px 70px 22px 70px' },
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <StyledLinkTermsConditionsText display="block">
          {copyRightText} © {new Date().getFullYear()}
          {reservedRightsRText}
        </StyledLinkTermsConditionsText>
      </Box>
    </ContainerBox>
  )
}

export default memo(Footer)
