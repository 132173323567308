import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { PaginationEmpty } from 'domain/models/queryPagination'
import { RootState } from 'presentation/redux/reducers'
import { PackageEmpty, PackageState } from 'domain/models/package'
import { ReadPackagesQueryResponse } from 'domain/entities/pacakge'

const initialState: PackageState = {
  isLoading: false,
  error: null,
  deleteError: null,
  uploadError: null,
  packagesList: [],
  package: PackageEmpty,
  pagination: PaginationEmpty,
}

export const packageSlice = createSlice({
  name: 'package',
  initialState,
  reducers: {
    getAllPackagesStart: (state: PackageState) => {
      state.isLoading = true
      state.error = null
      state.uploadError = null
    },
    getAllPackagesSuccess: (
      state: PackageState,
      action: PayloadAction<ReadPackagesQueryResponse>,
    ) => {
      state.isLoading = false
      state.packagesList = action.payload.entities
      state.pagination = action.payload.pagination
    },
    getAllPackagesError: (state: PackageState, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
    createPackageStart: (state: PackageState) => {
      state.isLoading = true
      state.error = null
    },
    createPackageSuccess: (state: PackageState) => {
      state.isLoading = false
    },
    createPackageError: (state: PackageState, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
    updatePackageStart: (state: PackageState) => {
      state.isLoading = true
      state.error = null
    },
    updatePackageSuccess: (state: PackageState) => {
      state.isLoading = false
    },
    updatePackageError: (state: PackageState, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
    deletePackageStart: (state: PackageState) => {
      state.isLoading = true
      state.error = null
      state.deleteError = null
    },
    deletePackageSuccess: (state: PackageState) => {
      state.isLoading = false
    },
    deletePackageError: (state: PackageState, action: PayloadAction<string>) => {
      state.isLoading = false
      state.deleteError = action.payload
    },
    uploadPackagesStart: (state: PackageState) => {
      state.isLoading = true
      state.error = null
    },
    uploadPackagesSuccess: (state: PackageState) => {
      state.isLoading = false
    },
    uploadPackagesError: (state: PackageState, action: PayloadAction<string>) => {
      state.isLoading = false
      state.uploadError = action.payload
    },
    clearErrors: (state: PackageState) => {
      state.error = null
      state.uploadError = null
      state.deleteError = null
    },
  },
})

export const packageState = (state: RootState) => state.package
export const {
  getAllPackagesStart,
  getAllPackagesSuccess,
  getAllPackagesError,
  createPackageStart,
  createPackageSuccess,
  createPackageError,
  updatePackageStart,
  updatePackageSuccess,
  updatePackageError,
  deletePackageStart,
  deletePackageSuccess,
  deletePackageError,
  uploadPackagesStart,
  uploadPackagesSuccess,
  uploadPackagesError,
  clearErrors,
} = packageSlice.actions

export default packageSlice.reducer
