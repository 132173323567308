import { lazy } from 'react'

import { urlRoutes } from 'presentation/routes/config/urlRoutes/urlRoutes'

const PackageAdministration = lazy(() => import('presentation/pages/packageAdministration'))
const CreatePackage = lazy(() => import('presentation/pages/createPackage'))
const EditPackage = lazy(() => import('presentation/pages/editPackage'))
export const packageAdministrationRoutes = [
  {
    nameUrl: 'Administración de paquetes',
    path: urlRoutes.private.packageAdministration.url,
    element: PackageAdministration,
    rols: ['*'],
  },
  {
    nameUrl: 'Creación de destino',
    path: urlRoutes.private.createPackage.url,
    element: CreatePackage,
    rols: ['*'],
  },
  {
    nameUrl: 'Edición de destino',
    path: urlRoutes.private.editPackage.url,
    element: EditPackage,
    rols: ['*'],
  },
]
