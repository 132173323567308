import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { PromotionController } from 'infraestructure/controllers/promotionController'
import { ReadGetPromotionQueryResponse } from 'domain/entities/promotion'
import { Status } from 'domain/models/apiResponse'
import {
  getPromotionError,
  getPromotionStart,
  getPromotionSuccess,
  promotionState,
} from 'presentation/redux/slices/promotionSlice'
import useError from 'presentation/hooks/useError'

export const useGetPromotion = () => {
  const dispatch = useDispatch()
  const { isLoading, error } = useSelector(promotionState)
  const { getErrorMessage } = useError()

  const getPromotion = useCallback(
    async (promotionId: string) => {
      dispatch(getPromotionStart())
      try {
        const response = await PromotionController.getPromotion(promotionId)
        if (response?.status === Status.success) {
          dispatch(getPromotionSuccess(response?.data as ReadGetPromotionQueryResponse))
        }
      } catch (err: any) {
        dispatch(getPromotionError(getErrorMessage({ error: err })))
      }
    },
    [dispatch, getErrorMessage],
  )

  return { getPromotion, isLoading, error }
}
