export const promotionTex = 'Promoción'
export const delegateText = 'Delegado'
export const stateText = 'Estado'
export const promotionAdministrationText = 'Administración de Promociones'
export const addText = 'Agregar'
export const addPromotionText = `${addText} ${promotionTex}`
export const bankAccountText = 'Cuenta bancaria'
export const dataForBankTransferText = 'Datos para la transferencia bancaria.'
export const createPromotionText = `Crear ${promotionTex}`
export const creationPromotionText = `Creación de ${promotionTex}`
export const editPromotionText = `Edición de ${promotionTex}`
export const nameText = 'Nombre'
export const LastNameText = 'Apellido'
export const committeeMemberText = 'Miembros del comité'
export const creationUserCommitteeText = 'Creación Usuario Comité'
export const creationUserCommitteeAlertText = '¡El usuario ya existe!'
export const userWidthIdentificationNumberText = 'El usuario con número de identificación'
export const ifYouWishAddtext = 'ya existe, si deseas agregarlo al comité de la promoción'
export const fromColegeText = 'del colegio'
export const presAddButtontext = 'presiona el botón "Agregar"'
export const editUserCommitteeText = `Edición Usuario Comité`
export const alertText = '¡INACTIVACIÓN DE LA PROMOCIÓN!'
export const deleteTitleText = '¡ELIMINACIÓN DE COMITÉ!'
export const confirmTex = 'Confirmar'
export const thisActionWillBeIrreversibleText =
  'Esta acción será IRREVERSIBLE. Al inactivar la promoción, se eliminará automáticamente el ahorro programado y el acceso de todos los usuarios a la plataforma.'
export const toContinueActionPleaseText =
  'Para continuar la acción verifica que todos los documentos hayan sido regularizados por la fiduciaria.'
export const activeText = 'Activo'
export const unActiveText = 'Inactivo'
export const addMemberText = 'Agregar miembro'
export const titleText = 'Estás seguro que deseas eliminar al usuario de comité'
export const rememberText =
  'Recuerda que al realizar esta acción el usuario ya no tendrá acceso al comité de la promoción'
export const collegeText = 'del colegio'
export const accessText =
  'y en caso de no ser representante o no pertenecer a ningún otro comité dejará de tener acceso a la plataforma.'
