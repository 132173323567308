import {
  emailText as emailGlobalText,
  identificationNumberText as identificationNumberGlobalText,
  passwordText,
} from 'presentation/constant/globals'

export const labels = {
  form: {
    requiredFieldText: 'Este campo no puede estar vacío ',
    invalidEmailText: 'El correo electrónico no es válido',
    minCharactersText: 'Debe contener al menos 8 caracteres',
    minCharactersIdentificationNumberText: `El ${identificationNumberGlobalText.toLowerCase()} debe tener al menos 4 caracteres`,
    notMatchPasswordsText: `Las ${passwordText}s no coinciden`,
    onlyNumbersText: 'Solo se permiten números',
    wrongDatesText: 'Has introducido datos incorrectos',
    wrongRucText: 'Este campo debe tener 13 caracteres',
    maxCharactersPhoneText: 'Este campo no debe tener más de 15 caracteres',
    maxCharactersIdentificationNumberText: `El ${identificationNumberGlobalText.toLowerCase()} debe tener 10 caracteres`,
    minCharactersYearPromotionText: 'Este campo debe tener menos de 4 caracteres',
    wrongPasswordsText: `La ${passwordText.toLowerCase()} debe tener al menos una mayúscula, números, letras y 1 caracter especial`,
    maxCharactersPasswordsText: `La ${passwordText.toLowerCase()} no puede ser mayor que 20`,
  },
  /* login page */
  login: {
    email: emailGlobalText,
    passwordText: `${passwordText.charAt(0).toUpperCase()}${passwordText.slice(1)}`,
  },
  recoverPassword: {
    identificationNumberText: identificationNumberGlobalText,
  },
  forgotPassword: {
    repeatPasswordText: `Repetir ${passwordText.toLowerCase()}`,
  },
  /* institution Form */
  institutionForm: {
    nameInstitutionText: 'Nombre de la institución',
    phoneNumberText: 'Teléfono',
    cityText: 'Ciudad',
    rucInstitutionText: 'RUC de la institución',
  },
  /* promotion Form */
  promotionForm: {
    promotionText: 'Promoción',
    codePromotionText: 'Código de Promoción',
    numberAccountText: 'Número de Cuenta',
    socialReasonText: 'Razón Social',
    rucText: 'RUC',
  },
  /* EditPromotionAddCommitteePopup */
  editPromotionAddCommitteePopup: {
    nameText: 'Nombres',
    lastNameText: 'Apellidos',
  },
}
