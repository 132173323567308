import { Box, Button, IconButton } from '@mui/material'
import {
  CheckCircleOutline as CheckCircleOutlineIcon,
  HighlightOff as HighlightOffIcon,
  VerticalAlignBottom as VerticalAlignBottomIcon,
} from '@mui/icons-material'

import { downloadButtonText } from 'presentation/constant/globals'
import { StyledAlert, StyledSnackbar } from './index.styled'

type CustomizedSnackbarProps = {
  openSnackbar: boolean
  handleCloseSnackbar: () => void
  message: string
  autoHideDuration?: number
  isSuccess?: boolean
  isShowButtonDownload?: boolean
  handleDownloadExcel?: () => void
}

export default function CustomizedSnackbar({
  openSnackbar = false,
  handleCloseSnackbar,
  isSuccess = false,
  autoHideDuration = 5000,
  message,
  isShowButtonDownload,
  handleDownloadExcel,
}: CustomizedSnackbarProps) {
  const vertical = 'top'
  const horizontal = 'right'

  const handleDownload = () => {
    if (handleDownloadExcel) {
      handleDownloadExcel()
    }
  }

  return (
    <StyledSnackbar
      open={openSnackbar}
      autoHideDuration={isShowButtonDownload ? null : autoHideDuration}
      anchorOrigin={{ vertical, horizontal }}
      onClose={handleCloseSnackbar}
    >
      <StyledAlert
        variant="outlined"
        severity={isSuccess ? 'info' : 'error'}
        elevation={6}
        icon={false}
        action={
          <Box sx={{ display: 'flex', gap: '20px' }}>
            {isShowButtonDownload && (
              <Button
                startIcon={<VerticalAlignBottomIcon fontSize="small" color="inherit" />}
                variant="outlined"
                color="info"
                onClick={() => handleDownload()}
                sx={{ width: '200px' }}
              >
                {downloadButtonText}
              </Button>
            )}
            <IconButton aria-label="close" size="small" onClick={() => handleCloseSnackbar()}>
              {isSuccess ? (
                <CheckCircleOutlineIcon color="success" />
              ) : (
                <HighlightOffIcon color="error" />
              )}
            </IconButton>
          </Box>
        }
      >
        {message}
      </StyledAlert>
    </StyledSnackbar>
  )
}
