import { createTheme } from '@mui/material/styles'

export const newTheme = (theme: any) =>
  createTheme({
    ...theme,
    components: {
      MuiDayCalendar: {
        styleOverrides: {
          weekDayLabel: {
            color: '#000000',
          },
        },
      },
      MuiPickersDay: {
        defaultProps: {
          styleOverrides: {
            root: {
              '& .Mui-selected': {
                color: '#FFFFFF',
                backgroundColor: '#124CFD',
              },
            },
          },
        },
      },
    },
  })
