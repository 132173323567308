import { Query } from 'domain/models/query'
import NotificationRepository from 'infraestructure/repositories/notificationRepository'
import { readGetAllNotificationsQuery } from 'application/queries/notification'

const notificationRepository = new NotificationRepository()

export class NotificationController {
  static async getAllNotifications(query?: Query) {
    return await readGetAllNotificationsQuery(notificationRepository, query)
  }
}
