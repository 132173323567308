import jwtDecode from 'jwt-decode'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { AuthUserController } from 'infraestructure/controllers/authUserController'
import { AuthLoginRepresentativeRequest } from 'domain/entities/authUser'
import { User } from 'domain/models/authUser'
import { Status } from 'domain/models/apiResponse'
import {
  authUserState,
  loginError,
  loginStart,
  loginSuccess,
} from 'presentation/redux/slices/authUserSlice'
import { accessTokenKey, userKey } from 'presentation/constant/sessionStorageKeys'
import { urlRoutes } from 'presentation/routes/config/urlRoutes/urlRoutes'
import useSessionStorage from 'presentation/hooks/useSessionStorage'
import useError from 'presentation/hooks/useError'

export const useLogin = () => {
  const dispatch = useDispatch()
  const { isLoading, error } = useSelector(authUserState)
  const navigate = useNavigate()
  const { saveDataAtSessionStorage } = useSessionStorage(userKey)
  const { getErrorMessage } = useError()
  const login = async (data: AuthLoginRepresentativeRequest) => {
    dispatch(loginStart())
    try {
      const response = await AuthUserController.login(data)
      if (response?.status === Status.success) {
        const token = response?.data?.access_token
        if (token) {
          const decodeToken = jwtDecode<User>(token)
          const userData = {
            logged: true,
            refreshToken: response?.data?.refresh_token as string,
            refreshExpiresIn: response?.data?.refresh_expires_in as number,
            user: decodeToken,
          }
          saveDataAtSessionStorage({ data: JSON.stringify(userData) })
          sessionStorage.setItem(
            accessTokenKey,
            JSON.stringify({
              accessToken: token,
              accessExpiresIn: response?.data?.access_expires_in as number,
            }),
          )
          const payload = {
            ...userData,
            token,
            accessExpiresIn: response?.data?.access_expires_in,
          }
          dispatch(loginSuccess(payload))
          navigate(urlRoutes.private.institutionAdministration.urlTo)
        }
      }
    } catch (err: any) {
      dispatch(loginError(getErrorMessage({ error: err })))
    }
  }
  return { login, isLoading, error }
}
