import IImageRepository from 'domain/interfaces/repositories/IImageRepository'
import { sendRequest } from 'infraestructure/dataSources/http/axios'
import { AsyncApiResponse } from 'domain/models/apiResponse'
import { UPLOAD_IMAGE_ENDPOINT } from 'infraestructure/repositories/endpoints.routes'
import { UploadImageCommandResponse } from 'domain/entities/image'

export default class ImageRepository implements IImageRepository {
  async uploadImage(file: FormData): AsyncApiResponse<UploadImageCommandResponse> {
    const request = {
      post: {
        path: `${UPLOAD_IMAGE_ENDPOINT}`,
        body: file,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    }
    return await sendRequest<UploadImageCommandResponse>(request)
  }
}
