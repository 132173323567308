import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { PromotionController } from 'infraestructure/controllers/promotionController'
import { ReadPromotionsQueryResponse } from 'domain/entities/promotion'
import { Query } from 'domain/models/query'
import { Status } from 'domain/models/apiResponse'
import {
  getAllPromotionsError,
  getAllPromotionsStart,
  getAllPromotionsSuccess,
  promotionState,
} from 'presentation/redux/slices/promotionSlice'
import useError from 'presentation/hooks/useError'

export const useGetAllPromotions = () => {
  const dispatch = useDispatch()
  const { isLoading, error } = useSelector(promotionState)
  const { getErrorMessage } = useError()

  const getAllPromotions = useCallback(
    async (institutionsId: string, query?: Query) => {
      dispatch(getAllPromotionsStart())
      try {
        const response = await PromotionController.getAllPromotions(institutionsId, query)
        if (response?.status === Status.success) {
          dispatch(getAllPromotionsSuccess(response?.data as ReadPromotionsQueryResponse))
        }
      } catch (err: any) {
        dispatch(getAllPromotionsError(getErrorMessage({ error: err })))
      }
    },
    [dispatch, getErrorMessage],
  )

  return { getAllPromotions, isLoading, error }
}
