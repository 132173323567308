import { ChangeEventHandler } from 'react'
import { Box, IconButton, useTheme } from '@mui/material'
import { Close as CloseIcon, Search as SearchIcon } from '@mui/icons-material'

import { InputApp } from 'presentation/styles/index.styled'
import { searchText } from 'presentation/constant/placeholders'

type SearchInputProps = {
  value: string
  handleChange: ChangeEventHandler
  handleSubmit: () => void
  handleClearInput: () => void
  isClickSearchButton: boolean
}

export default function SearchInput({
  handleChange,
  handleSubmit,
  value,
  handleClearInput,
  isClickSearchButton,
}: SearchInputProps) {
  const theme = useTheme()

  return (
    <form onSubmit={handleSubmit}>
      <InputApp placeholder={searchText} onChange={handleChange} value={value} fullWidth />
      <Box
        sx={{
          position: 'absolute',
          top: '1px',
          right: '2px',
          backgroundColor: theme.mixins.secondaryZircon.color,
          borderRadius: '0px 4px 4px 0px',
          height: '35px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <IconButton onClick={handleSubmit} disabled={!value}>
          <SearchIcon color="primary" />
        </IconButton>
      </Box>
      {isClickSearchButton && (
        <Box
          sx={{
            position: 'absolute',
            top: '1px',
            right: '50px',
            height: '35px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IconButton onClick={handleClearInput}>
            <CloseIcon color="error" />
          </IconButton>
        </Box>
      )}
    </form>
  )
}
