import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { PaginationEmpty } from 'domain/models/queryPagination'
import { NotificationState } from 'domain/models/notification'
import { ReadNotificationsQueryResponse } from 'domain/entities/notification'
import { RootState } from 'presentation/redux/reducers'

const initialState: NotificationState = {
  isLoading: false,
  error: null,
  notifications: [],
  pagination: PaginationEmpty,
}

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    getAllNotificationsStart: (state: NotificationState) => {
      state.isLoading = true
      state.error = null
      state.notifications = []
    },
    getAllNotificationsSuccess: (
      state: NotificationState,
      action: PayloadAction<ReadNotificationsQueryResponse>,
    ) => {
      state.isLoading = false
      state.notifications = action.payload?.entities
      state.pagination = action.payload?.pagination
    },
    getAllNotificationsError: (state: NotificationState, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
  },
})

export const notificationState = (state: RootState) => state.notification
export const { getAllNotificationsStart, getAllNotificationsSuccess, getAllNotificationsError } =
  notificationSlice.actions

export default notificationSlice.reducer
