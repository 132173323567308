import IPromotionRepository from 'domain/interfaces/repositories/IPromotionRepository'
import { CreatePromotionCommitteeCommandRequest } from 'domain/entities/promotion'

export const createPromotionCommitteeCommand = (
  repository: IPromotionRepository,
  promotionId: string,
  data: CreatePromotionCommitteeCommandRequest,
) => {
  return repository.createPromotionCommittee(promotionId, data)
}
