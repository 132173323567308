import { ReactNode } from 'react'
import { Typography, useTheme } from '@mui/material'

type TextTitleProps = {
  children: ReactNode
  color?: string
  fontWeight?: number
  fontSize?: string
  lineHeight?: string
}

export function TextTitle({ children, color, fontWeight, fontSize, lineHeight }: TextTitleProps) {
  return (
    <Typography
      fontWeight={fontWeight || 900}
      fontSize={fontSize || '30px'}
      lineHeight={lineHeight || '35px'}
      color={color || 'primary'}
    >
      {children}
    </Typography>
  )
}

type TextSubTitleProps = {
  children: ReactNode
  color?: string
  fontSize?: string
  lineHeight?: string
  fontWeight?: number
}

export function TextSubTitle({
  children,
  color,
  fontSize,
  lineHeight,
  fontWeight,
}: TextSubTitleProps) {
  return (
    <Typography
      fontWeight={fontWeight || 700}
      fontSize={fontSize || '20px'}
      lineHeight={lineHeight || '24px'}
      color={color || 'primary'}
    >
      {children}
    </Typography>
  )
}

type TextBoldProps = {
  children: ReactNode
  color?: string
  fontSize?: string
  lineHeight?: string
  fontWeight?: number
}

export function TextBold({ children, fontWeight, color, fontSize, lineHeight }: TextBoldProps) {
  return (
    <Typography
      fontWeight={fontWeight || 500}
      fontSize={fontSize || '20px'}
      lineHeight={lineHeight || '23.44px'}
      color={color || 'info'}
    >
      {children}
    </Typography>
  )
}

type TextParagraphProps = {
  children: ReactNode
  fontWeight?: number
  color?: string
  fontSize?: string
  lineHeight?: string
}

export function TextParagraph({
  children,
  fontWeight,
  fontSize,
  color,
  lineHeight,
}: TextParagraphProps) {
  return (
    <Typography
      fontWeight={fontWeight || 400}
      fontSize={fontSize || 16}
      lineHeight={lineHeight || '20px'}
      color={color || 'primary'}
    >
      {children}
    </Typography>
  )
}

type PrivacyPolicyTextProps = {
  children: ReactNode
  fontWeight?: number
  fontSize?: string
  lineHeight?: string
}

export function PrivacyPolicyText({
  children,
  fontWeight,
  fontSize,
  lineHeight,
}: PrivacyPolicyTextProps) {
  const theme = useTheme()
  return (
    <Typography
      fontWeight={fontWeight || 500}
      fontSize={fontSize || '20px'}
      lineHeight={lineHeight || '23.44px'}
      color={theme.mixins.workSans.color}
    >
      {children}
    </Typography>
  )
}
