import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { PaginationEmpty } from 'domain/models/queryPagination'
import { DestinationState } from 'domain/models/destination'
import { ReadDestinationsQueryResponse } from 'domain/entities/destination'
import { RootState } from 'presentation/redux/reducers'

const initialState: DestinationState = {
  isLoading: false,
  error: null,
  destinationList: [],
  pagination: PaginationEmpty,
}

export const destinationSlice = createSlice({
  name: 'destination',
  initialState,
  reducers: {
    getAllDestinationsStart: (state: DestinationState) => {
      state.isLoading = true
      state.error = null
    },
    getAllDestinationsSuccess: (
      state: DestinationState,
      action: PayloadAction<ReadDestinationsQueryResponse>,
    ) => {
      state.isLoading = false
      state.destinationList = action.payload.entities
      state.pagination = action.payload.pagination
    },
    getAllDestinationsError: (state: DestinationState, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
    updateDestinationStart: (state: DestinationState) => {
      state.isLoading = true
      state.error = null
    },
    updateDestinationSuccess: (state: DestinationState) => {
      state.isLoading = false
    },
    updateDestinationError: (state: DestinationState, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
  },
})

export const destinationState = (state: RootState) => state.destination
export const {
  getAllDestinationsStart,
  getAllDestinationsSuccess,
  getAllDestinationsError,
  updateDestinationStart,
  updateDestinationSuccess,
  updateDestinationError,
} = destinationSlice.actions

export default destinationSlice.reducer
