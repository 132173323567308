import INotificationRepository from 'domain/interfaces/repositories/INotificationRepository'
import { Query } from 'domain/models/query'
import { sendRequest } from 'infraestructure/dataSources/http/axios'
import { getQueryString } from 'infraestructure/utils/format'
import { AsyncApiResponse } from 'domain/models/apiResponse'
import { ReadNotificationsQueryResponse } from 'domain/entities/notification'
import { GET_ALL_NOTIFICATIONS_ENDPOINT } from './endpoints.routes'

export default class NotificationRepository implements INotificationRepository {
  async getAllNotifications(query: Query): AsyncApiResponse<ReadNotificationsQueryResponse> {
    const queryOrder = query
      ? `?orders=createAt__DESC&${getQueryString(query)}`
      : '?orders=createAt__DESC&'
    const request = {
      get: {
        path: `${GET_ALL_NOTIFICATIONS_ENDPOINT}${queryOrder}`,
      },
    }
    return await sendRequest<ReadNotificationsQueryResponse>(request)
  }
}
