import { MouseEventHandler, ReactNode } from 'react'
import { Box } from '@mui/material'

import { ButtonTextApp } from 'presentation/components/elements'
import { ExpandMoreIcon, StyledBadge } from './index.styled'

type HeaderWrapperNavBarItemsProps = {
  isShowArrowDownIcon?: boolean
  handleOpenMenu?: MouseEventHandler
  icon?: ReactNode
  isBadge?: boolean
  children: ReactNode | ReactNode[]
}

export default function HeaderWrapperNavBarItems({
  isShowArrowDownIcon = true,
  handleOpenMenu,
  icon,
  isBadge = false,
  children,
}: HeaderWrapperNavBarItemsProps) {
  return (
    <ButtonTextApp
      endIcon={isShowArrowDownIcon && <ExpandMoreIcon color="info" fontSize="large" />}
      onClick={handleOpenMenu}
    >
      {isBadge ? (
        <StyledBadge badgeContent={4} color="primary">
          {icon}
        </StyledBadge>
      ) : (
        <Box>{icon}</Box>
      )}
      <Box sx={{ marginLeft: '7px' }}>{children}</Box>
    </ButtonTextApp>
  )
}
