import { MouseEventHandler } from 'react'
import { useSelector } from 'react-redux'
import { MenuItem } from '@mui/material'
import { AccountCircleOutlined as AccountCircleOutlinedIcon } from '@mui/icons-material'

import { authUserState } from 'presentation/redux/slices/authUserSlice'
import { useLogout } from 'presentation/redux/hooks/authUser'
import { StyledItemMenuText } from 'presentation/components/layout/components/Header/header.styled'
import {
  HeaderMenu,
  HeaderWrapperNavBarItems,
} from 'presentation/components/layout/components/Header/components'
import { signOffText } from 'presentation/constant/texts/header'

type HeaderAccountMenuProps = {
  isOpenUserMenu: boolean
  handleClickListUserItemMenu: MouseEventHandler<HTMLElement>
  handleCloseUserMenu: () => void
  anchorUserEl: any
}

export default function HeaderAccountMenu({
  isOpenUserMenu,
  handleClickListUserItemMenu,
  handleCloseUserMenu,
  anchorUserEl,
}: HeaderAccountMenuProps) {
  const { logout } = useLogout()
  const { refreshToken, user } = useSelector(authUserState)

  const handleLogout = async () => {
    await logout(refreshToken)
  }

  return (
    <HeaderWrapperNavBarItems
      icon={<AccountCircleOutlinedIcon color="info" fontSize="large" />}
      handleOpenMenu={handleClickListUserItemMenu}
    >
      <StyledItemMenuText fontWeight={300} sx={{ lineHeight: '30px' }}>
        {user?.fullName}
      </StyledItemMenuText>
      <HeaderMenu
        anchorEl={anchorUserEl}
        isOpenMenu={isOpenUserMenu}
        handleCloseMenu={handleCloseUserMenu}
      >
        <MenuItem onClick={handleLogout}>{signOffText}</MenuItem>
      </HeaderMenu>
    </HeaderWrapperNavBarItems>
  )
}
