import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { PaginationEmpty } from 'domain/models/queryPagination'
import { institutionEmpty, InstitutionState } from 'domain/models/institution'
import { Institution, ReadInstitutionsQueryResponse } from 'domain/entities/institution'
import { RootState } from 'presentation/redux/reducers'

const initialState: InstitutionState = {
  isLoading: false,
  error: null,
  institutions: [],
  pagination: PaginationEmpty,
  institution: institutionEmpty,
}
export const institutionSlice = createSlice({
  name: 'institution',
  initialState,
  reducers: {
    getAllInstitutionsStart: (state: InstitutionState) => {
      state.isLoading = true
      state.error = null
    },
    getAllInstitutionsSuccess: (
      state: InstitutionState,
      action: PayloadAction<ReadInstitutionsQueryResponse>,
    ) => {
      state.isLoading = false
      state.institutions = action.payload.entities
      state.pagination = action.payload.pagination
    },
    getAllInstitutionsError: (state: InstitutionState, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
    getInstitutionStart: (state: InstitutionState) => {
      state.isLoading = true
      state.error = null
    },
    getInstitutionSuccess: (state: InstitutionState, action: PayloadAction<Institution>) => {
      state.isLoading = false
      state.institution = action.payload
    },
    getInstitutionError: (state: InstitutionState, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
    createInstitutionStart: (state: InstitutionState) => {
      state.isLoading = true
      state.error = null
    },
    createInstitutionSuccess: (state: InstitutionState) => {
      state.isLoading = false
    },
    createInstitutionError: (state: InstitutionState, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
    updateInstitutionStart: (state: InstitutionState) => {
      state.isLoading = true
      state.error = null
    },
    updateInstitutionSuccess: (state: InstitutionState) => {
      state.isLoading = false
    },
    updateInstitutionError: (state: InstitutionState, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
  },
})

export const institutionState = (state: RootState) => state.institution
export const {
  getAllInstitutionsStart,
  getAllInstitutionsSuccess,
  getAllInstitutionsError,
  getInstitutionStart,
  getInstitutionSuccess,
  getInstitutionError,
  createInstitutionStart,
  createInstitutionSuccess,
  createInstitutionError,
  updateInstitutionStart,
  updateInstitutionError,
  updateInstitutionSuccess,
} = institutionSlice.actions

export default institutionSlice.reducer
