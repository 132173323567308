import { lazy } from 'react'

import { urlRoutes } from 'presentation/routes/config/urlRoutes/urlRoutes'

const DataPrivacyPolicy = lazy(() => import('presentation/pages/dataPrivacyPolicy'))

export const FooterLinkRoutes = [
  {
    path: urlRoutes.public.footer.dataPrivacyPolicy.url,
    element: DataPrivacyPolicy,
  },
]
