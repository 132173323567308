import { Box } from '@mui/material'
import moment from 'moment'

import { CreateOrUpdatePackageCommandRequest, Package } from 'domain/entities/pacakge'
import { FormControlApp } from 'presentation/components/form/input'
import { InputApp, StyledLink, StyledRating } from 'presentation/styles/index.styled'
import {
  PackageFormAddItem,
  PackageFormItemList,
} from 'presentation/components/elements/packageForm/components'
import {
  AlertError,
  ButtonApp,
  CustomizedSnackbar,
  GridContainer,
  GridItem,
  InputDatePicker,
  LoadingBackdrop,
  UploadImage,
} from 'presentation/components/elements'
import { backText } from 'presentation/constant/globals'
import { urlRoutes } from 'presentation/routes/config/urlRoutes/urlRoutes'
import ImageIcon from 'presentation/assets/images/picture.png'

import { usePackageForm } from './hooks/usePackageForm'

type PackageFormProps = {
  packageData?: Package
  handleSave: (data: CreateOrUpdatePackageCommandRequest) => void
  isLoadingSave: boolean
  errorSave: string
}
export default function PackageForm({
  handleSave,
  packageData,
  isLoadingSave,
  errorSave,
}: PackageFormProps) {
  const {
    errors,
    register,
    watch,
    handleChangeStartDate,
    handleChangeEndDate,
    handleAddItemToObservationsList,
    handleAddItemToNotIncludeList,
    handleAddItemToIncludeList,
    includeList,
    notIncludeList,
    observationsList,
    handleDeleteItemFromObservationsList,
    handleDeleteItemFromNotIncludeList,
    handleDeleteItemFromIncludeList,
    isDisabledButton,
    saveButtonText,
    handleSetObservation,
    handleSetNotInclude,
    handleSetInclude,
    notInclude,
    include,
    observation,
    handleEditItemFromNotIncludeList,
    handleEditItemFromObservationList,
    handleEditItemFromIncludeList,
    rating,
    handleChangeRating,
    startDate,
    endDate,
    handleSavePackage,
    isLoadingUploadImage,
    image,
    handleChangeImage,
    uploadImageError,
    errorResponseUploadImage,
    handleClearError,
  } = usePackageForm({ packageData, handleSave })

  return (
    <Box>
      {isLoadingSave && <LoadingBackdrop />}
      {!!errorSave && (
        <Box sx={{ margin: '15px 0' }}>
          <AlertError text={errorSave} />
        </Box>
      )}
      <GridContainer spacing={8}>
        <GridItem lg={6}>
          <GridContainer>
            <GridItem md={6} xl={4}>
              <UploadImage
                handleChangeImage={handleChangeImage}
                isLoadingUploadImage={isLoadingUploadImage}
                image={image || ImageIcon}
              />
            </GridItem>
            <GridItem md={6} xl={8}>
              <GridContainer>
                <GridItem>
                  <GridContainer>
                    <GridItem xl={6}>
                      <FormControlApp
                        label="Fecha de inicio"
                        errorMessage={errors.startDate?.message}
                        isRequired
                      >
                        <InputDatePicker
                          value={startDate}
                          handleChange={handleChangeStartDate}
                          minDate={moment().toDate()}
                        />
                      </FormControlApp>
                    </GridItem>
                    <GridItem xl={6}>
                      <FormControlApp
                        label="Fecha de fin"
                        errorMessage={errors.endDate?.message}
                        isRequired
                      >
                        <InputDatePicker
                          value={endDate}
                          handleChange={handleChangeEndDate}
                          minDate={moment(watch().startDate).toDate()}
                          isDisabled={!startDate}
                        />
                      </FormControlApp>
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem>
                  <GridContainer>
                    <GridItem xl={6}>
                      <FormControlApp
                        label="Cantidad de noches"
                        errorMessage={errors.nights?.message}
                        isRequired
                      >
                        <InputApp {...register('nights')} />
                      </FormControlApp>
                    </GridItem>
                    <GridItem xl={6}>
                      <FormControlApp
                        label="Cantidad de días"
                        errorMessage={errors.days?.message}
                        isRequired
                      >
                        <InputApp {...register('days')} />
                      </FormControlApp>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem>
              <FormControlApp
                label="Nombre del destino"
                errorMessage={errors.destination?.message}
                isRequired
              >
                <InputApp {...register('destination')} />
              </FormControlApp>
            </GridItem>
            <GridItem>
              <FormControlApp
                label="Nombre del paquete"
                errorMessage={errors.packageName?.message}
                isRequired
              >
                <InputApp {...register('packageName')} />
              </FormControlApp>
            </GridItem>
            <GridItem>
              <FormControlApp
                label="Nombre del hotel"
                errorMessage={errors.hotel?.message}
                isRequired
              >
                <InputApp {...register('hotel')} />
              </FormControlApp>
            </GridItem>
            <GridItem>
              <FormControlApp
                label="Description"
                errorMessage={errors.description?.message}
                isRequired
              >
                <InputApp rows={4} multiline {...register('description')} />
              </FormControlApp>
            </GridItem>
            <GridItem>
              <GridContainer>
                <GridItem md={4}>
                  <FormControlApp label="Precio" errorMessage={errors.price?.message} isRequired>
                    <InputApp {...register('price')} />
                  </FormControlApp>
                </GridItem>
                <GridItem md={4}>
                  <FormControlApp
                    label="Id del paquete"
                    errorMessage={errors.packageId?.message}
                    isRequired
                  >
                    <InputApp {...register('packageId')} />
                  </FormControlApp>
                </GridItem>
                <GridItem md={4}>
                  <FormControlApp
                    label="Capacidad máxima"
                    errorMessage={errors.maxCapacity?.message}
                    isRequired
                  >
                    <InputApp {...register('maxCapacity')} />
                  </FormControlApp>
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem md={12} lg={10} xl={8}>
              <GridContainer>
                <GridItem md={5} lg={6}>
                  <FormControlApp
                    label="Categoría del hotel"
                    errorMessage={errors.rating?.message}
                    isRequired
                  >
                    <Box sx={{ marginTop: '15px' }}>
                      <StyledRating value={rating} onChange={handleChangeRating} />
                    </Box>
                  </FormControlApp>
                </GridItem>
                <GridItem md={4} lg={6}>
                  <FormControlApp
                    label="Número de personas (incluidas en el precio)"
                    errorMessage={errors.numberPersonByIncludePrice?.message}
                    isRequired
                  >
                    <InputApp {...register('numberPersonByIncludePrice')} />
                  </FormControlApp>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem lg={6}>
          <GridContainer>
            <GridItem>
              <PackageFormAddItem
                handleAddItem={handleAddItemToIncludeList}
                register={register}
                label="Incluye"
                name="include"
                element={include}
                handleEditItem={handleEditItemFromIncludeList}
                isEmptyInput={!!watch().include}
              />
              {includeList.length ? (
                <PackageFormItemList
                  itemList={includeList}
                  handleDeleteItem={handleDeleteItemFromIncludeList}
                  handleSetElement={handleSetInclude}
                />
              ) : (
                ''
              )}
            </GridItem>
            <GridItem>
              <PackageFormAddItem
                handleAddItem={handleAddItemToNotIncludeList}
                register={register}
                label="No incluye"
                name="notInclude"
                element={notInclude}
                handleEditItem={handleEditItemFromNotIncludeList}
                isEmptyInput={!!watch().notInclude}
              />
              {notIncludeList.length ? (
                <PackageFormItemList
                  itemList={notIncludeList}
                  handleDeleteItem={handleDeleteItemFromNotIncludeList}
                  handleSetElement={handleSetNotInclude}
                />
              ) : (
                ''
              )}
            </GridItem>
            <GridItem>
              <PackageFormAddItem
                handleAddItem={handleAddItemToObservationsList}
                register={register}
                label="Observaciones"
                name="observation"
                element={observation}
                handleEditItem={handleEditItemFromObservationList}
                isEmptyInput={!!watch().observation}
              />
              {observationsList.length ? (
                <PackageFormItemList
                  itemList={observationsList}
                  handleDeleteItem={handleDeleteItemFromObservationsList}
                  handleSetElement={handleSetObservation}
                />
              ) : (
                ''
              )}
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              justifyContent: 'flex-end',
              gap: '20px',
              marginTop: { xs: '40px', md: '100px' },
            }}
          >
            <StyledLink to={urlRoutes.private.packageAdministration.urlTo}>
              <ButtonApp isOutlined paddingX="74.3px">
                {backText}
              </ButtonApp>
            </StyledLink>
            <ButtonApp paddingX="48px" isDisabled={isDisabledButton} onClick={handleSavePackage}>
              {saveButtonText}
            </ButtonApp>
          </Box>
        </GridItem>
      </GridContainer>
      {(!!uploadImageError || !!errorResponseUploadImage) && (
        <CustomizedSnackbar
          openSnackbar={!!uploadImageError || !!errorResponseUploadImage}
          handleCloseSnackbar={handleClearError}
          message={uploadImageError || errorResponseUploadImage}
        />
      )}
    </Box>
  )
}
