import { ReactNode } from 'react'
import { Box } from '@mui/material'

import PolygonIcon from 'presentation/assets/polygon.svg'
import { StyledDivider } from 'presentation/components/layout/components/Header/header.styled'
import { StyledMenu } from './index.styled'

type HeaderMenuProps = {
  children: ReactNode
  anchorEl: any
  isOpenMenu: boolean
  handleCloseMenu: () => void
  isUseGap?: boolean
}

function HeaderMenu({
  children,
  anchorEl,
  isOpenMenu,
  handleCloseMenu,
  isUseGap,
}: HeaderMenuProps) {
  return (
    <StyledMenu anchorEl={anchorEl} open={isOpenMenu} onClose={handleCloseMenu}>
      <Box
        sx={{
          position: 'relative',
          maxWidth: '478px',
          width: '100%',
          padding: '5px 10px',
          height: '100%',
        }}
      >
        <Box
          sx={{
            padding: '10px 18px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: isUseGap ? '14px' : 0,
            height: '100%',
          }}
        >
          <StyledDivider orientation="vertical" flexItem />
          <Box>{children}</Box>
        </Box>
      </Box>
      <Box
        sx={{
          position: 'absolute',
          top: '-8px',
          left: '30px',
          zIndex: 2800,
        }}
      >
        <img alt="Polygon" src={PolygonIcon} />
      </Box>
    </StyledMenu>
  )
}

export default HeaderMenu
