import { lazy } from 'react'

import { urlRoutes } from 'presentation/routes/config/urlRoutes/urlRoutes'

const InstitutionAdministration = lazy(() => import('presentation/pages/institutionAdministration'))
const CreateInstitution = lazy(() => import('presentation/pages/createInstitution'))
const EditInstitution = lazy(() => import('presentation/pages/editInstitution'))
export const institutionAdministrationRoutes = [
	{
		nameUrl: 'Creación y administración de instituciones',
		path: urlRoutes.private.institutionAdministration.url,
		element: InstitutionAdministration,
		rols: ['*']
	},
	{
		nameUrl: 'Creación de institución',
		path: urlRoutes.private.createInstitution.url,
		element: CreateInstitution,
		rols: ['*']
	},
	{
		nameUrl: 'Edición de institución',
		path: urlRoutes.private.editInstitution.url,
		element: EditInstitution,
		rols: ['*']
	}
]
