import { labels } from 'presentation/constant/labels'
import { validateCellPhone } from 'presentation/utils/format'

export const validatePhone = (
  newValue: string,
  setStateError: any,
  setState: any,
  oldValue: string,
) => {
  if (newValue === '') {
    setStateError(labels.form.requiredFieldText)
  } else if (!validateCellPhone(newValue)) {
    setState(oldValue)
  } else if (newValue.length > 18) {
    setState(oldValue)
  } else {
    setStateError('')
  }
}
