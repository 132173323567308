import { Box, CircularProgress, ClickAwayListener, useTheme } from '@mui/material'
import { Notifications as NotificationIcon } from '@mui/icons-material'

import { QueryPagination } from 'domain/models/queryPagination'
import { Notification } from 'domain/entities/notification'
import {
  StyledItemMenuText,
  StyledPagination,
} from 'presentation/components/layout/components/Header/header.styled'
import { notificationsText, thereIsNotNotificationsText } from 'presentation/constant/texts/header'
import EllipseBlueIcon from 'presentation/assets/ellipse-blue.svg'
import {
  ExpandMoreIcon,
  StyledBadge,
} from 'presentation/components/layout/components/Header/components/HeaderWrapperNavBarItems/index.styled'
import { AlertError, ButtonTextApp, TextParagraph } from 'presentation/components/elements'
import { HeaderNotificationsModal } from 'presentation/components/layout/components/Header/components'
import { StyledBox } from './index.styled'

type HeaderNotificationsNavItemProps = {
  isOpenNotificationMenu: boolean
  handleOpenNotificationMenu: () => void
  handleCloseNotificationMenu: () => void
  handleChangePageNotification: (event: unknown, newPage: number) => void
  pageSize: number
  page: number
  handleShowNotificationModal: (item: any) => void
  isOpenNotificationModal: boolean
  handleCloseNotificationModal: () => void
  notification: Notification
  error: string
  notifications: Notification[]
  isLoading: boolean
  pagination: QueryPagination
}

export default function HeaderNotificationsNavItem({
  isOpenNotificationMenu,
  handleOpenNotificationMenu,
  handleCloseNotificationMenu,
  page,
  handleChangePageNotification,
  pageSize,
  isOpenNotificationModal,
  notification,
  handleCloseNotificationModal,
  handleShowNotificationModal,
  isLoading,
  notifications,
  error,
  pagination,
}: HeaderNotificationsNavItemProps) {
  const theme = useTheme()
  const isShowPagination = pagination?.total > pageSize

  return (
    <ClickAwayListener onClickAway={handleCloseNotificationMenu}>
      <Box sx={{ position: 'relative', marginTop: '5px' }}>
        <ButtonTextApp
          endIcon={<ExpandMoreIcon color="info" fontSize="large" />}
          onClick={handleOpenNotificationMenu}
        >
          <StyledBadge badgeContent={pagination.total} color="primary">
            <NotificationIcon color="info" fontSize="large" />
          </StyledBadge>
          <Box sx={{ marginLeft: '7px' }}>
            <StyledItemMenuText fontWeight={300} sx={{ lineHeight: '30px' }}>
              {notificationsText}
            </StyledItemMenuText>
          </Box>
        </ButtonTextApp>
        {isOpenNotificationMenu && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '13px',
              position: 'absolute',
              top: '75px',
              left: '-80px',
              borderRadius: '4px',
              width: '350px',
              padding: isShowPagination ? '40px 30px 0 30px' : '40px 30px',
              background: theme.palette.primary.main,
            }}
          >
            {isLoading && (
              <Box sx={{ textAlign: 'center' }}>
                <CircularProgress color="info" size={70} />
              </Box>
            )}
            {!!error && <AlertError text={error} />}

            {notifications.length ? (
              <StyledBox>
                {notifications.map((item: Notification) => (
                  <Box
                    key={item?.id}
                    sx={{
                      display: 'flex',
                      gap: '28px',
                      width: '100%',
                      cursor: 'pointer',
                      alignItems: 'center',
                      paddingY: '4px',
                    }}
                    onClick={() => handleShowNotificationModal(item)}
                  >
                    <img
                      alt={EllipseBlueIcon}
                      src={EllipseBlueIcon}
                      style={{ width: '8px', height: '8px' }}
                    />
                    <StyledItemMenuText
                      fontWeight={500}
                      sx={{
                        lineHeight: '20px',
                        '&:hover': { color: 'info.main' },
                      }}
                    >
                      {item?.message}
                    </StyledItemMenuText>
                  </Box>
                ))}
              </StyledBox>
            ) : (
              <TextParagraph color="inherit">{thereIsNotNotificationsText}</TextParagraph>
            )}
            {pagination.total > pageSize && (
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <StyledPagination
                  count={pagination?.total}
                  onPageChange={handleChangePageNotification}
                  page={page}
                  rowsPerPage={pageSize}
                  rowsPerPageOptions={[pageSize]}
                />
              </Box>
            )}
          </Box>
        )}
        {isOpenNotificationModal && (
          <HeaderNotificationsModal
            isOpenModal={isOpenNotificationModal}
            handleCloseModal={handleCloseNotificationModal}
            notification={notification}
          />
        )}
      </Box>
    </ClickAwayListener>
  )
}
