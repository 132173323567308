import { MouseEvent, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { Notification } from 'domain/entities/notification'
import { authUserState } from 'presentation/redux/slices/authUserSlice'
import { useGetAllNotifications } from 'presentation/redux/hooks/notification'

export const useHeader = () => {
  const [anchorUserEl, setAnchorUserEl] = useState<null | HTMLElement>(null)
  const [isOpenNotificationMenu, setIsOpenNotificationMenu] = useState<boolean>(false)
  const [isOpenNotificationModal, setIsOpenNotificationModal] = useState<boolean>(false)
  const [notification, setNotification] = useState<Notification | null>(null)
  const [page, setPage] = useState<number>(0)
  const { logged } = useSelector(authUserState)
  const { isLoading, error, getAllNotifications, notifications, pagination } =
    useGetAllNotifications()
  const pageSize = 10
  const isOpenUserMenu = !!anchorUserEl

  useEffect(() => {
    if (logged) {
      const handleGetAllNotifications = async () => {
        const query = { page, pageSize }
        await getAllNotifications(query)
      }
      handleGetAllNotifications()
    }
  }, [page, logged, pageSize])

  const handleChangePageNotification = async (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleShowNotificationModal = (item: any) => {
    setIsOpenNotificationModal(true)
    setNotification(item)
  }

  const handleCloseNotificationModal = () => {
    setIsOpenNotificationModal(false)
    setNotification(null)
  }

  const handleCloseUserMenu = () => {
    setAnchorUserEl(null)
  }

  const handleClickListUserItemMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorUserEl(isOpenUserMenu ? null : event.currentTarget)
  }
  const handleCloseNotificationMenu = () => {
    setIsOpenNotificationMenu(false)
    handleCloseNotificationModal()
  }

  const handleOpenNotificationMenu = () => {
    setIsOpenNotificationMenu(true)
    if (anchorUserEl) {
      setAnchorUserEl(null)
    }
  }

  return {
    handleClickListUserItemMenu,
    handleCloseUserMenu,
    isOpenUserMenu,
    anchorUserEl,
    isOpenNotificationMenu,
    handleCloseNotificationMenu,
    handleOpenNotificationMenu,
    page,
    pageSize,
    handleChangePageNotification,
    isOpenNotificationModal,
    handleShowNotificationModal,
    notification,
    handleCloseNotificationModal,
    notifications,
    pagination,
    isLoading,
    error,
    logged,
  }
}
