import { labels } from 'presentation/constant/labels'
import { validateEmail } from 'presentation/utils/format'

export const validateEmailAddress = (email: string) => {
  if (email === '') {
    return labels.form.requiredFieldText
  }
  if (!validateEmail(email)) {
    return labels.form.invalidEmailText
  }
  return ''
}
