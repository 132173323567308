import { Alert, Typography } from '@mui/material'

type AlertErrorProps = {
  text: string | null
}

export default function AlertError({ text }: AlertErrorProps) {
  return (
    <Alert severity="error" variant="outlined">
      <Typography color="error" fontWeight={500} fontSize="14px">
        {text || 'Error al procesar la petición'}
      </Typography>
    </Alert>
  )
}
