import * as yup from 'yup'
import { labels } from 'presentation/constant/labels'

export type FormData = {
  packageId: string
  destination: string
  packageName: string
  description: string
  hotel: string
  numberPersonByIncludePrice: number
  maxCapacity: number
  nights: number
  days: number
  rating: number
  startDate: string
  endDate: string
  include: string
  notInclude: string
  observation: string
  price: number
  image: string
}
const regExpressionToValidateSpaces = /^\S/
const regExpressionToValidateOnlyNumbers = /^[\d]*$/
export const schema = yup.object({
  packageId: yup
    .string()
    .required('Ingrese el id del paquete')
    .max(15, 'Este campo solo permite hasta 15 caracteres')
    .matches(regExpressionToValidateSpaces, 'Por favor introduzca un id de paquete válido'),
  destination: yup
    .string()
    .required('Ingrese el destino')
    .max(40, 'Este campo solo permite hasta 40 caracteres')
    .matches(regExpressionToValidateSpaces, 'Por favor introduzca un destino válido'),
  packageName: yup
    .string()
    .required('Ingrese el nombre del paquete')
    .max(40, 'Este campo solo permite hasta 40 caracteres')
    .matches(regExpressionToValidateSpaces, 'Por favor introduzca un nombre válido'),
  description: yup
    .string()
    .required('Ingrese la descripción')
    .max(300, 'Este campo solo permite hasta 300 caracteres')
    .matches(/^\S[\da-zA-ZÀ-ÿ\s.,"!¡]*$/, 'Por favor introduzca una descripción válida'),
  hotel: yup
    .string()
    .required('Ingrese el hotel')
    .max(50, 'Este campo solo permite hasta 50 caracteres')
    .matches(regExpressionToValidateSpaces, 'Por favor introduzca un hotel válido'),
  numberPersonByIncludePrice: yup
    .string()
    .required('Ingrese el número de personas')
    .matches(regExpressionToValidateOnlyNumbers, labels.form.onlyNumbersText),
  maxCapacity: yup
    .string()
    .required('Ingrese la capacidad máxima')
    .matches(regExpressionToValidateOnlyNumbers, labels.form.onlyNumbersText),
  nights: yup
    .string()
    .required('Ingrese las noches')
    .matches(regExpressionToValidateOnlyNumbers, labels.form.onlyNumbersText),
  days: yup
    .string()
    .required('Ingrese los días')
    .matches(regExpressionToValidateOnlyNumbers, labels.form.onlyNumbersText),
  price: yup
    .string()
    .required('Ingrese el precio')
    .matches(regExpressionToValidateOnlyNumbers, labels.form.onlyNumbersText),
  rating: yup.number(),
  startDate: yup.string(),
  endDate: yup.string(),
  include: yup.string(),
  notInclude: yup.string(),
  observation: yup.string(),
  image: yup.string(),
})
