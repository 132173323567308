import { getIntoText } from 'presentation/constant/globals'
import { urlRoutes } from 'presentation/routes/config/urlRoutes/urlRoutes'
import { ButtonApp } from 'presentation/components/elements'
import { StyledLink } from 'presentation/styles/index.styled'

export default function HeaderSignInItem() {
  return (
    <StyledLink to={urlRoutes.public.auth.login.urlTo}>
      <ButtonApp>{getIntoText}</ButtonApp>
    </StyledLink>
  )
}
