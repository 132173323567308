import {
  dataPrivacyPolicyText as dataPrivacyPolicyGlobalText,
  termsAndConditionsText as termsAndConditionsGlobalText,
} from 'presentation/constant/globals'

export const tripsPromText = 'Viaje'
export const eventsPromText = 'Fiesta de Graduación'

export const termsAndConditionsText = termsAndConditionsGlobalText
export const frequentQuestionsText = 'Preguntas frecuentes'
export const dataPrivacyPolicyText = dataPrivacyPolicyGlobalText
export const siteMapText = 'Mapa del Sitio'
export const reservedRightsRText = ' Diners Club Ecuador. Derechos reservados'
export const copyRightText = 'Copyright'
