import { urlRoutes } from 'presentation/routes/config/urlRoutes/urlRoutes'
import {
  adminManagementText,
  creationAndAdministrationInstitutionsText,
  destinationsAdministrationText,
  documentManagementText,
  packagesAdministrationText,
} from 'presentation/constant/texts/sidebar'

export const sideBarElements = [
  {
    id: 1,
    route: urlRoutes.private.institutionAdministration.urlTo,
    expandedName: 'panel1',
    ariaControls: 'panel1d-content',
    panelId: 'panel1d-header',
    name: creationAndAdministrationInstitutionsText,
  },
  {
    id: 2,
    route: urlRoutes.private.documentManagement.urlTo,
    expandedName: 'panel2',
    ariaControls: 'panel2d-content',
    panelId: 'panel2d-header',
    name: documentManagementText,
  },
  {
    id: 3,
    route: urlRoutes.private.adminManagement.urlTo,
    expandedName: 'panel3',
    ariaControls: 'panel3d-content',
    panelId: 'panel3d-header',
    name: adminManagementText,
  },
  {
    id: 4,
    route: urlRoutes.private.packageAdministration.urlTo,
    expandedName: 'panel4',
    ariaControls: 'panel4d-content',
    panelId: 'panel4d-header',
    name: packagesAdministrationText,
  },
  {
    id: 5,
    route: urlRoutes.private.destinationAdministration.urlTo,
    expandedName: 'panel5',
    ariaControls: 'panel5d-content',
    panelId: 'panel5d-header',
    name: destinationsAdministrationText,
  },
]
