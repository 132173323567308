import { memo } from 'react'
import { Box } from '@mui/material'

import { Notification } from 'domain/entities/notification'
import {
  HeaderAccountUserNavItem,
  HeaderNotificationsNavItem,
} from 'presentation/components/layout/components/Header/components'
import { useHeader } from 'presentation/components/layout/components/Header/hooks'

function HeaderNavItems() {
  const {
    handleCloseUserMenu,
    isOpenUserMenu,
    handleClickListUserItemMenu,
    anchorUserEl,
    isOpenNotificationMenu,
    handleOpenNotificationMenu,
    handleCloseNotificationMenu,
    handleChangePageNotification,
    pageSize,
    page,
    handleShowNotificationModal,
    isOpenNotificationModal,
    handleCloseNotificationModal,
    notification,
    error,
    notifications,
    pagination,
    isLoading,
  } = useHeader()

  return (
    <Box
      sx={{
        display: 'flex',
        gap: '12px',
        flexWrap: 'wrap',
      }}
    >
      <HeaderNotificationsNavItem
        isOpenNotificationMenu={isOpenNotificationMenu}
        handleOpenNotificationMenu={handleOpenNotificationMenu}
        handleCloseNotificationMenu={handleCloseNotificationMenu}
        handleChangePageNotification={handleChangePageNotification}
        pageSize={pageSize}
        page={page}
        handleShowNotificationModal={handleShowNotificationModal}
        isOpenNotificationModal={isOpenNotificationModal}
        handleCloseNotificationModal={handleCloseNotificationModal}
        notification={notification as Notification}
        error={error as string}
        notifications={notifications}
        pagination={pagination}
        isLoading={isLoading}
      />
      <HeaderAccountUserNavItem
        isOpenUserMenu={isOpenUserMenu}
        handleClickListUserItemMenu={handleClickListUserItemMenu}
        handleCloseUserMenu={handleCloseUserMenu}
        anchorUserEl={anchorUserEl}
      />
    </Box>
  )
}

export default memo(HeaderNavItems)
