import { ReactNode } from 'react'
import { FormControl, Typography } from '@mui/material'

import { InputLabelApp } from 'presentation/styles/index.styled'

type FormControlAppProps = {
  label?: string | undefined
  errorMessage?: string | any
  isRequired?: boolean
  noGutter?: boolean
  children: ReactNode
  isDisabled?: boolean
}

export function FormControlApp({
  label,
  errorMessage = '',
  isRequired,
  noGutter,
  children,
  isDisabled,
}: FormControlAppProps) {
  return (
    <FormControl fullWidth variant="standard">
      {label !== undefined && (
        <InputLabelApp
          required={isRequired}
          error={!!errorMessage}
          shrink
          style={{ color: 'primary' }}
          disabled={isDisabled}
        >
          {label}
        </InputLabelApp>
      )}
      {children}
      {!noGutter && (
        <Typography variant="caption" color="error">
          {errorMessage}
          {'\u00A0'}
        </Typography>
      )}
    </FormControl>
  )
}
