import { styled } from '@mui/material/styles'
import { Alert, Snackbar } from '@mui/material'

export const StyledSnackbar = styled(Snackbar)(() => ({
  '&.MuiSnackbar-root': {
    zIndex: 1700,
    maxWidth: '800px',
    width: '100%',
    marginTop: '50px',
  },
}))
export const StyledAlert = styled(Alert)(({ theme }) => ({
  '&.MuiAlert-root': {
    backgroundColor: theme.palette.background.default,
    borderRadius: '4px',
  },
  '& .MuiAlert-message': {
    color: theme.palette.primary.main,
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '24px',
  },
  border: `1px solid ${theme.palette.info.main}`,
}))
