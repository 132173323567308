import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { PromotionController } from 'infraestructure/controllers/promotionController'
import { Status } from 'domain/models/apiResponse'
import { CreatePromotionCommandRequest } from 'domain/entities/promotion'
import {
  createPromotionError,
  createPromotionStart,
  createPromotionSuccess,
  promotionState,
} from 'presentation/redux/slices/promotionSlice'
import useError from 'presentation/hooks/useError'

export const useCreatePromotion = () => {
  const dispatch = useDispatch()
  const { isLoading, errorCreatePromotion } = useSelector(promotionState)
  const { getErrorMessage } = useError()

  const createPromotion = useCallback(
    async (institutionId: string, data: CreatePromotionCommandRequest) => {
      dispatch(createPromotionStart())
      try {
        const response = await PromotionController.createPromotion(institutionId, data)
        if (response?.status === Status.success) {
          dispatch(createPromotionSuccess())
          return true
        }
      } catch (err: any) {
        dispatch(createPromotionError(getErrorMessage({ error: err })))
        return false
      }
    },
    [dispatch, getErrorMessage],
  )

  return { createPromotion, isLoading, errorCreatePromotion }
}
