import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { PaginationEmpty } from 'domain/models/queryPagination'
import { PromotionEmpty, PromotionState } from 'domain/models/promotion'
import {
  ReadGetPromotionQueryResponse,
  ReadPromotionsQueryResponse,
} from 'domain/entities/promotion'
import { RootState } from 'presentation/redux/reducers'

const initialState: PromotionState = {
  isLoading: false,
  error: null,
  errorCreatePromotion: null,
  errorUpdatePromotion: null,
  errorCreateCommittee: null,
  errorUpdateCommittee: null,
  errorSetDelegate: null,
  promotions: [],
  promotion: PromotionEmpty,
  pagination: PaginationEmpty,
}

export const promotionSlice = createSlice({
  name: 'promotion',
  initialState,
  reducers: {
    getAllPromotionsStart: (state: PromotionState) => {
      state.isLoading = true
      state.error = null
      state.errorUpdatePromotion = null
      state.errorCreatePromotion = null
      state.errorCreateCommittee = null
      state.errorUpdateCommittee = null
      state.errorSetDelegate = null
    },
    getAllPromotionsSuccess: (
      state: PromotionState,
      action: PayloadAction<ReadPromotionsQueryResponse>,
    ) => {
      state.isLoading = false
      state.promotions = action.payload.entities
      state.pagination = action.payload.pagination
    },
    getAllPromotionsError: (state: PromotionState, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
    getPromotionStart: (state: PromotionState) => {
      state.isLoading = true
      state.error = null
      state.errorUpdatePromotion = null
      state.errorCreatePromotion = null
      state.errorCreateCommittee = null
      state.errorUpdateCommittee = null
      state.errorSetDelegate = null
    },
    getPromotionSuccess: (
      state: PromotionState,
      action: PayloadAction<ReadGetPromotionQueryResponse>,
    ) => {
      state.isLoading = false
      state.promotion = action.payload
    },
    getPromotionError: (state: PromotionState, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
    createPromotionStart: (state: PromotionState) => {
      state.isLoading = true
      state.error = null
      state.errorUpdatePromotion = null
      state.errorCreatePromotion = null
      state.errorCreateCommittee = null
      state.errorUpdateCommittee = null
      state.errorSetDelegate = null
    },
    createPromotionSuccess: (state: PromotionState) => {
      state.isLoading = false
    },
    createPromotionError: (state: PromotionState, action: PayloadAction<string>) => {
      state.isLoading = false
      state.errorCreatePromotion = action.payload
    },
    updatePromotionStart: (state: PromotionState) => {
      state.isLoading = true
      state.error = null
      state.errorUpdatePromotion = null
      state.errorCreatePromotion = null
      state.errorCreateCommittee = null
      state.errorUpdateCommittee = null
      state.errorSetDelegate = null
    },
    updatePromotionSuccess: (state: PromotionState) => {
      state.isLoading = false
    },
    updatePromotionError: (state: PromotionState, action: PayloadAction<string>) => {
      state.errorUpdatePromotion = action.payload
      state.isLoading = false
    },
    createPromotionCommitteeStart: (state: PromotionState) => {
      state.isLoading = true
      state.error = null
      state.errorUpdatePromotion = null
      state.errorCreatePromotion = null
      state.errorCreateCommittee = null
      state.errorUpdateCommittee = null
      state.errorSetDelegate = null
    },
    createPromotionCommitteeSuccess: (state: PromotionState) => {
      state.isLoading = false
    },
    createPromotionCommitteeError: (state: PromotionState, action: PayloadAction<string>) => {
      state.errorCreateCommittee = action.payload
      state.isLoading = false
    },
    createPromotionCommitteeConflictError: (state: PromotionState) => {
      state.isLoading = false
    },
    updatePromotionCommitteeStart: (state: PromotionState) => {
      state.isLoading = true
      state.error = null
      state.errorUpdatePromotion = null
      state.errorCreatePromotion = null
      state.errorCreateCommittee = null
      state.errorUpdateCommittee = null
      state.errorSetDelegate = null
    },
    updatePromotionCommitteeSuccess: (state: PromotionState) => {
      state.isLoading = false
    },
    updatePromotionCommitteeError: (state: PromotionState, action: PayloadAction<string>) => {
      state.isLoading = false
      state.errorUpdateCommittee = action.payload
    },
    updatePromotionSetDelegateStart: (state: PromotionState) => {
      state.isLoading = true
      state.error = null
      state.errorUpdatePromotion = null
      state.errorCreatePromotion = null
      state.errorCreateCommittee = null
      state.errorUpdateCommittee = null
      state.errorSetDelegate = null
    },
    updatePromotionSetDelegateSuccess: (state: PromotionState) => {
      state.isLoading = false
    },
    updatePromotionSetDelegateError: (state: PromotionState, action: PayloadAction<string>) => {
      state.isLoading = false
      state.errorSetDelegate = action.payload
    },
    clearError: (state) => {
      state.errorCreateCommittee = null
      state.error = null
      state.errorCreatePromotion = null
      state.errorSetDelegate = null
      state.errorUpdatePromotion = null
      state.errorUpdateCommittee = null
    },
  },
})

export const promotionState = (state: RootState) => state.promotion
export const {
  getAllPromotionsSuccess,
  getAllPromotionsStart,
  getAllPromotionsError,
  createPromotionStart,
  createPromotionSuccess,
  createPromotionError,
  updatePromotionError,
  updatePromotionSuccess,
  updatePromotionStart,
  getPromotionStart,
  getPromotionSuccess,
  getPromotionError,
  updatePromotionCommitteeError,
  createPromotionCommitteeStart,
  createPromotionCommitteeSuccess,
  updatePromotionCommitteeStart,
  updatePromotionCommitteeSuccess,
  createPromotionCommitteeError,
  updatePromotionSetDelegateError,
  updatePromotionSetDelegateStart,
  updatePromotionSetDelegateSuccess,
  createPromotionCommitteeConflictError,
  clearError,
} = promotionSlice.actions

export default promotionSlice.reducer
