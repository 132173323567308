import { ReactNode } from 'react'
import { Button, useTheme } from '@mui/material'

type ButtonTextAppProps = {
  onClick?: any
  children: ReactNode
  color?: 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error' | 'inherit'
  startIcon?: any
  endIcon?: any
  size?: 'small' | 'medium' | 'large'
  padding?: string
}

export function ButtonTextApp({
  onClick,
  children,
  color = 'inherit',
  startIcon,
  endIcon,
  size,
  padding,
}: ButtonTextAppProps) {
  const theme = useTheme()

  return (
    <Button
      onClick={onClick}
      color={color}
      variant="text"
      size={size}
      sx={{
        fontWeight: 300,
        textTransform: 'inherit',
        '&:hover': {
          color: theme.palette.info.main,
        },
        padding,
      }}
      startIcon={startIcon}
      endIcon={endIcon}
    >
      {children}
    </Button>
  )
}

type ButtonAppProps = {
  isSecondary?: boolean
  isOutlined?: boolean
  isDisabled?: boolean
  isSubmitType?: boolean
  isFullWidth?: boolean
  children: ReactNode
  startIcon?: any
  endIcon?: any
  paddingX?: string
  onClick?: any
  size?: 'small' | 'medium' | 'large'
  height?: string
}

export function ButtonApp({
  isSecondary,
  isOutlined,
  isDisabled,
  isSubmitType,
  height,
  isFullWidth,
  paddingX,
  onClick,
  children,
  startIcon = null,
  endIcon = null,
  size = 'medium',
}: ButtonAppProps) {
  return (
    <Button
      color="info"
      size={size}
      variant={isOutlined ? 'outlined' : (isSecondary && 'text') || 'contained'}
      disabled={isDisabled}
      type={isSubmitType ? 'submit' : 'button'}
      fullWidth={isFullWidth}
      sx={{
        paddingX: `${!isFullWidth && paddingX ? paddingX : '41.7px'}`,
        height: height || '50px',
      }}
      startIcon={startIcon}
      endIcon={endIcon}
      onClick={onClick}
    >
      {children}
    </Button>
  )
}
