import { lazy } from 'react'

import { urlRoutes } from 'presentation/routes/config/urlRoutes/urlRoutes'

export const AuthRoutes = [
  {
    path: urlRoutes.public.auth.login.url,
    element: lazy(() => import('presentation/pages/auth/login')),
  },
  {
    path: urlRoutes.public.auth.recoverPassword.url,
    element: lazy(() => import('presentation/pages/auth/recoverPassword')),
  },
  {
    path: urlRoutes.public.auth.forgotPassword.url,
    element: lazy(() => import('presentation/pages/auth/forgotPassword')),
  },
]
