export const passwordText = 'contraseña' // NOSONAR
export const requestPass = 'Solicitar contraseña'
export const termsAndConditionsText = 'Términos y condiciones'
export const loginText = 'Iniciar sesión'
export const getIntoText = 'Ingresar'
export const dataPrivacyPolicyText = 'Política de privacidad de datos'
export const editText = 'Editar'
export const backText = 'Regresar'
export const adminText = 'Administrar'
export const commentsText = 'Comentarios'
export const emailText = 'Correo electrónico'
export const saveChangesText = 'Guardar cambios'
export const phoneNumberText = 'Teléfono'
export const activeText = 'Activar'
export const unActiveText = 'Inactivar'
export const actionToAllowActiveOrUnactiveText =
  'Acción que permite activar o inactivar la promoción.'
export const cancelText = 'Cancelar'
export const continueText = 'CONTINUAR'
export const identificationNumberText = 'Número de identificación'
export const deleteText = 'Eliminar'
export const wrongFormatMessageText = 'ARCHIVO CON FORMATO INCORRECTO'
export const fileExceedsMessageText = 'EL ARCHIVO EXCEDE EL PESO REQUERIDO'
export const downloadButtonText = 'Descargar Excel'
export const uploadImageText = 'Subir foto'
