import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { PaginationEmpty } from 'domain/models/queryPagination'
import { AdminState } from 'domain/models/admin'
import { RootState } from 'presentation/redux/reducers'
import { Admin, ReadAdminsQueryResponse } from 'domain/entities/admin'

const initialState: AdminState = {
  isLoading: false,
  error: null,
  errorCreateOrUpdate: null,
  admins: [],
  pagination: PaginationEmpty,
}

export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    getAllAdminsStart: (state: AdminState) => {
      state.isLoading = true
      state.error = null
      state.errorCreateOrUpdate = null
    },
    getAllAdminsSuccess: (state: AdminState, action: PayloadAction<ReadAdminsQueryResponse>) => {
      state.isLoading = false
      state.admins = action.payload.entities
      state.pagination = action.payload.pagination
    },
    getAllAdminsError: (state: AdminState, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
    createAdminStart: (state: AdminState) => {
      state.isLoading = true
      state.error = null
      state.errorCreateOrUpdate = null
    },
    createAdminSuccess: (state: AdminState) => {
      state.isLoading = false
    },
    createAdminError: (state: AdminState, action: PayloadAction<string>) => {
      state.isLoading = false
      state.errorCreateOrUpdate = action.payload
    },
    updateAdminStart: (state: AdminState) => {
      state.isLoading = true
      state.error = null
      state.errorCreateOrUpdate = null
    },
    updateAdminSuccess: (state: AdminState) => {
      state.isLoading = false
    },
    updateAdminError: (state: AdminState, action: PayloadAction<string>) => {
      state.isLoading = false
      state.errorCreateOrUpdate = action.payload
    },
    createCommentsStart: (state: AdminState) => {
      state.isLoading = true
      state.error = null
      state.errorCreateOrUpdate = null
    },
    createCommentsSuccess: (state: AdminState) => {
      state.isLoading = false
    },
    createCommentsError: (state: AdminState, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
    setListAdmins: (state: AdminState, action: PayloadAction<Admin[]>) => {
      state.admins = action.payload
    },
  },
})

export const adminState = (state: RootState) => state.admin
export const {
  getAllAdminsStart,
  createAdminStart,
  createAdminSuccess,
  getAllAdminsError,
  getAllAdminsSuccess,
  updateAdminError,
  updateAdminSuccess,
  updateAdminStart,
  createAdminError,
  createCommentsError,
  createCommentsSuccess,
  createCommentsStart,
  setListAdmins,
} = adminSlice.actions

export default adminSlice.reducer
