import {
  backText as backGlobalText,
  editText as editGlobalText,
} from 'presentation/constant/globals'

const INSTITUTION = 'Institución'
export const institutionsAdministrationText = 'Administración de Instituciones'
export const editText = editGlobalText
export const adminPromotionsText = 'Administrar promociones'
export const createInstitutionText = `Creación de ${INSTITUTION}`
export const backText = backGlobalText
export const deleteText = 'Eliminar'
export const addInstitutionText = `Agregar ${INSTITUTION}`
export const editInstitutionText = `Edición  de ${INSTITUTION}`

export const nameInstitutionText = 'Nombre de la Institución'
export const rucText = 'RUC'
export const cityText = 'Ciudad'
