import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  AuthUserState,
  PayloadLoginOrRefreshTokenAction,
  TokenLocalStorage,
  TokenLocalStorageEmpty,
  UserEmpty,
  UserLocalStorage,
  UserLocalStorageEmpty,
} from 'domain/models/authUser'
import { RecoverPasswordRepresentativeCommandResponse } from 'domain/entities/authUser'
import { RootState } from 'presentation/redux/reducers'
import { accessTokenKey, userKey } from 'presentation/constant/sessionStorageKeys'

const userInfo: UserLocalStorage =
  JSON.parse(sessionStorage.getItem(userKey) as string) || UserLocalStorageEmpty
const token: TokenLocalStorage =
  JSON.parse(sessionStorage.getItem(accessTokenKey) as string) || TokenLocalStorageEmpty

const initialState: AuthUserState = {
  isLoading: false,
  errorRefreshToken: null,
  error: null,
  logged: userInfo.logged,
  user: userInfo.user,
  token: token.accessToken,
  refreshToken: userInfo.refreshToken,
  refreshExpiresIn: userInfo.refreshExpiresIn,
  accessExpiresIn: token.accessExpiresIn,
  userForgotPassword: null,
}
export const authUserSlice = createSlice({
  name: 'authUser',
  initialState,
  reducers: {
    loginStart: (state: AuthUserState) => {
      state.isLoading = true
      state.error = null
      state.errorRefreshToken = null
    },
    loginSuccess: (
      state: AuthUserState,
      action: PayloadAction<PayloadLoginOrRefreshTokenAction>,
    ) => {
      state.isLoading = false
      state.logged = action.payload.logged
      state.user = action.payload.user
      state.token = action.payload.token
      state.refreshToken = action.payload.refreshToken
      state.refreshExpiresIn = action.payload.refreshExpiresIn
      state.accessExpiresIn = action.payload.accessExpiresIn as number
    },
    loginError: (state: AuthUserState, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
    logoutStart: (state: AuthUserState) => {
      state.isLoading = true
      state.error = null
      state.errorRefreshToken = null
    },
    logoutSuccess: (state: AuthUserState) => {
      state.isLoading = false
      state.logged = false
      state.user = UserEmpty
      state.token = ''
      state.refreshToken = ''
      state.accessExpiresIn = 0
      state.refreshExpiresIn = 0
    },
    logoutError: (state: AuthUserState, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
    refreshTokenStart: (state: AuthUserState) => {
      state.isLoading = true
      state.error = null
      state.errorRefreshToken = null
    },
    refreshTokenSuccess: (
      state: AuthUserState,
      action: PayloadAction<PayloadLoginOrRefreshTokenAction>,
    ) => {
      state.isLoading = false
      state.logged = action.payload.logged
      state.user = action.payload.user
      state.token = action.payload.token
      state.refreshToken = action.payload.refreshToken
      state.accessExpiresIn = action.payload.accessExpiresIn as number
      state.refreshExpiresIn = action.payload.refreshExpiresIn
    },
    refreshTokenError: (state: AuthUserState, action: PayloadAction<string>) => {
      state.isLoading = false
      state.errorRefreshToken = action.payload
    },
    recoverPasswordStart: (state: AuthUserState) => {
      state.isLoading = true
      state.error = null
      state.errorRefreshToken = null
    },
    recoverPasswordSuccess: (
      state: AuthUserState,
      action: PayloadAction<RecoverPasswordRepresentativeCommandResponse>,
    ) => {
      state.isLoading = false
      state.userForgotPassword = action.payload
    },
    recoverPasswordError: (state: AuthUserState, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
    resetPasswordStart: (state: AuthUserState) => {
      state.isLoading = true
      state.error = null
      state.errorRefreshToken = null
    },
    resetPasswordSuccess: (state: AuthUserState) => {
      state.isLoading = false
    },
    resetPasswordError: (state: AuthUserState, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
    clearErrorAuth: (state: AuthUserState) => {
      state.error = null
      state.errorRefreshToken = null
    },
  },
})

export const authUserState = (state: RootState) => state.authUser

export const {
  loginStart,
  loginError,
  loginSuccess,
  logoutStart,
  logoutSuccess,
  logoutError,
  refreshTokenStart,
  refreshTokenSuccess,
  refreshTokenError,
  recoverPasswordSuccess,
  recoverPasswordStart,
  recoverPasswordError,
  clearErrorAuth,
  resetPasswordError,
  resetPasswordSuccess,
  resetPasswordStart,
} = authUserSlice.actions

export default authUserSlice.reducer
