import { ChangeEvent, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { CreateInstitutionCommandRequest, Institution } from 'domain/entities/institution'
import { urlRoutes } from 'presentation/routes/config/urlRoutes/urlRoutes'
import { validateRuc } from 'presentation/utils/validateRuc'
import { validateEmailAddress } from 'presentation/utils/validateEmailAddress'
import { validatePhone } from 'presentation/utils/validaetCellPhone'
import { validateInputString } from 'presentation/utils/validateInputString'
import { labels } from 'presentation/constant/labels'
import { validateNumbersAndLetters } from 'presentation/utils/format'

type UseInstitutionFormProps = {
  institution?: Institution
  handleSubmit: (data: CreateInstitutionCommandRequest) => Promise<boolean | undefined>
}

export const useInstitutionForm = ({ institution, handleSubmit }: UseInstitutionFormProps) => {
  const [nameInstitution, setNameInstitution] = useState<string>('')
  const [phoneNumber, setPhoneNumber] = useState<string>('')
  const [city, setCity] = useState<string>('')
  const [rucInstitution, setRucInstitution] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [nameError, setNameError] = useState<string>('')
  const [phoneNumberError, setPhoneNumberError] = useState<string>('')
  const [cityError, setCityError] = useState<string>('')
  const [rucError, setRucError] = useState<string>('')
  const [emailError, setEmailError] = useState<string>('')
  const navigate = useNavigate()

  useEffect(() => {
    if (institution) {
      setRucInstitution(institution?.ruc)
      setCity(institution?.city)
      setEmail(institution?.email)
      setPhoneNumber(institution.telephone)
      setNameInstitution(institution.name)
    }
  }, [institution])

  const handleChangeNameInstitution = (oldValue: any, event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setNameInstitution(value)
    if (value === '') {
      setNameError(labels.form.requiredFieldText)
    } else if (!validateNumbersAndLetters(value)) {
      setNameInstitution(oldValue)
    } else {
      setNameError('')
    }
  }
  const handleChangePhoneNumber = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setPhoneNumber(value)
    validatePhone(value, setPhoneNumberError, setPhoneNumber, phoneNumber)
  }
  const handleChangeCity = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setCity(value)
    validateInputString(value, setCityError, setCity, city)
  }
  const handleChangeRucInstitution = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setRucInstitution(value)
    validateRuc(value, setRucError, setRucInstitution, rucInstitution)
  }

  const handleChangeEmail = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setEmailError(validateEmailAddress(value))
    setEmail(value)
  }

  const institutionsListUrl = urlRoutes.private.institutionAdministration.urlTo

  const handleCreateOrUpdateInstitution = async () => {
    const data = {
      name: nameInstitution,
      ruc: rucInstitution,
      city,
      email,
      telephone: phoneNumber,
    }
    const response = await handleSubmit(data)
    if (response) {
      navigate(institutionsListUrl)
    }
  }
  const isDisabledButton =
    !nameInstitution ||
    !email ||
    !city ||
    !phoneNumber ||
    !rucInstitution ||
    !!nameError ||
    !!rucError ||
    !!phoneNumberError ||
    !!emailError ||
    !!cityError

  return {
    isDisabledButton,
    handleCreateOrUpdateInstitution,
    handleChangeNameInstitution,
    handleChangeCity,
    handleChangeEmail,
    handleChangeRucInstitution,
    handleChangePhoneNumber,
    nameInstitution,
    nameError,
    city,
    cityError,
    email,
    emailError,
    rucError,
    rucInstitution,
    phoneNumber,
    phoneNumberError,
    institutionsListUrl,
  }
}
