import { labels } from 'presentation/constant/labels'
import { validateIdentification } from 'presentation/utils/format'

export const validateIdentificationNumber = (
  newValue: string,
  setStateError: any,
  setState: any,
  oldValue: string,
) => {
  if (newValue === '') {
    setStateError(labels.form.requiredFieldText)
  } else if (newValue.length < 4) {
    setStateError(labels.form.minCharactersIdentificationNumberText)
  } else if (!validateIdentification(newValue)) {
    setState(oldValue)
  } else if (newValue.length > 16) {
    setState(oldValue)
  } else {
    setStateError('')
  }
}
