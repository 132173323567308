import { combineReducers } from '@reduxjs/toolkit'

import authUserReducer from 'presentation/redux/slices/authUserSlice'
import institutionReducer from 'presentation/redux/slices/institutionSlice'
import promotionReducer from 'presentation/redux/slices/promotionSlice'
import adminReducer from 'presentation/redux/slices/adminSlice'
import fileReducer from 'presentation/redux/slices/fileSlice'
import representativeReducer from 'presentation/redux/slices/representativeSlice'
import notificationReducer from 'presentation/redux/slices/notificationSlice'
import packageReducer from 'presentation/redux/slices/packageSlice'
import destinationReducer from 'presentation/redux/slices/destinationSlice'

const rootReducer = combineReducers({
  authUser: authUserReducer,
  institution: institutionReducer,
  promotion: promotionReducer,
  admin: adminReducer,
  file: fileReducer,
  notification: notificationReducer,
  representative: representativeReducer,
  package: packageReducer,
  destination: destinationReducer,
})

export type RootState = ReturnType<typeof rootReducer>
export default rootReducer
