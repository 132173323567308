import { Box, useTheme } from '@mui/material'

import { LoadingBackdrop, TextParagraph } from 'presentation/components/elements'
import { uploadImageText } from 'presentation/constant/globals'

type UploadImageProps = {
  handleChangeImage: (event: any) => void
  isLoadingUploadImage: boolean
  image: string
}

function UploadImage({ isLoadingUploadImage, image, handleChangeImage }: UploadImageProps) {
  const theme = useTheme()

  return (
    <label htmlFor="upload-image">
      <Box
        sx={{
          width: '100%',
          minHeight: '140px',
          height: '100%',
          border: `1px solid ${theme.palette.info.light}`,
          borderRadius: '5px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
        }}
      >
        <input
          type="file"
          name="image"
          id="upload-image"
          hidden
          onChange={handleChangeImage}
          disabled={isLoadingUploadImage}
        />
        {isLoadingUploadImage && <LoadingBackdrop />}
        {image ? (
          <img
            src={image}
            alt={`${image}`}
            style={{ width: '100%', height: '100%', borderRadius: '5px' }}
          />
        ) : (
          <TextParagraph fontSize="12px" color={theme.palette.secondary.main}>
            {uploadImageText}
          </TextParagraph>
        )}
      </Box>
    </label>
  )
}

export default UploadImage
