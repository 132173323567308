import { useDispatch } from 'react-redux'
import { clearErrorAuth } from 'presentation/redux/slices/authUserSlice'

export const useClearErrorAuth = () => {
  const dispatch = useDispatch()
  const clearError = async () => {
    dispatch(clearErrorAuth())
  }

  return { clearError }
}
