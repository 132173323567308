export const packageAdministrationText = 'Administración de Paquetes'
export const packageBulkLoadButtonText = 'Carga Masiva de paquetes'
export const addPackageButtonText = 'Agregar Paquete'
export const editPackageButtonText = 'Editar Paquete'
export const deletePackageTitleModal = 'ELIMINAR PAQUETE'
export const deletePackageText =
  'Tu paquete será eliminado, para continuar da clic en el botón "ACEPTAR". Caso contrario da clic en el botón "REGRESAR"'
export const addPackageTittleText = 'PAQUETE AGREGADO EXITOSAMENTE'
export const editPackageTittleText = 'PAQUETE ACTUALIZADO EXITOSAMENTE'
export const addPackageSuccessText = 'Tu paquete fue agregado de manera correcta.'
export const editPackageSuccessText = 'Tu paquete fue actualizado de manera correcta.'
export const loadFileSuccessMessageText = 'CARGA DE PAQUETES EXITOSA'
export const errorResponseMessageText =
  'SUBIÓ UN ARCHIVO CON ERRORES, PULSE EL BOTÓN SI DESEA DESCARGAR LOS ERRORES'
