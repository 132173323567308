import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { PromotionController } from 'infraestructure/controllers/promotionController'
import { Status } from 'domain/models/apiResponse'
import {
  promotionState,
  updatePromotionSetDelegateError,
  updatePromotionSetDelegateStart,
  updatePromotionSetDelegateSuccess,
} from 'presentation/redux/slices/promotionSlice'
import useError from 'presentation/hooks/useError'

export const useUpdatePromotionSetDelegate = () => {
  const dispatch = useDispatch()
  const { isLoading, errorSetDelegate } = useSelector(promotionState)
  const { getErrorMessage } = useError()

  const updatePromotionSetDelegate = useCallback(
    async (promotionId: string, representativeId: string) => {
      dispatch(updatePromotionSetDelegateStart())
      try {
        const response = await PromotionController.updatePromotionSetDelegate(
          promotionId,
          representativeId,
        )
        if (response?.status === Status.success) {
          dispatch(updatePromotionSetDelegateSuccess())
          return true
        }
      } catch (err: any) {
        dispatch(updatePromotionSetDelegateError(getErrorMessage({ error: err })))
        return false
      }
    },
    [dispatch, getErrorMessage],
  )

  return { updatePromotionSetDelegate, isLoading, errorSetDelegate }
}
