import { memo } from 'react'
import { Box } from '@mui/material'
import { Link } from 'react-router-dom'

import { CreateInstitutionCommandRequest, Institution } from 'domain/entities/institution'
import { FormControlApp } from 'presentation/components/form/input'
import { InputApp } from 'presentation/styles/index.styled'
import { labels } from 'presentation/constant/labels'
import { ButtonApp } from 'presentation/components/elements/button'
import { backText } from 'presentation/constant/texts/institutionAdministration'
import { StyleContainerInputsBox, StyleWrapperInputsBox } from './index.styled'
import { useInstitutionForm } from './hooks'

type InstitutionFormProps = {
  institution?: Institution
  submitButtonText: string
  handleSubmit: (data: CreateInstitutionCommandRequest) => Promise<boolean | undefined>
}

function InstitutionForm({ institution, submitButtonText, handleSubmit }: InstitutionFormProps) {
  const {
    isDisabledButton,
    handleCreateOrUpdateInstitution,
    rucInstitution,
    nameInstitution,
    rucError,
    handleChangeRucInstitution,
    handleChangeCity,
    handleChangeNameInstitution,
    city,
    handleChangeEmail,
    email,
    handleChangePhoneNumber,
    phoneNumber,
    phoneNumberError,
    nameError,
    cityError,
    emailError,
    institutionsListUrl,
  } = useInstitutionForm({ institution, handleSubmit })

  return (
    <form onSubmit={handleCreateOrUpdateInstitution}>
      <Box
        sx={{
          padding: '48px 0 0 67px',
          display: 'flex',
          flexDirection: 'column',
          gap: '34px',
          maxWidth: '1087px',
          width: '100%',
        }}
      >
        <StyleContainerInputsBox>
          <StyleWrapperInputsBox>
            <FormControlApp
              label={labels.institutionForm.nameInstitutionText}
              errorMessage={nameError}
            >
              <InputApp
                name="nameInstitution"
                value={nameInstitution}
                onChange={(event: any) => handleChangeNameInstitution(nameInstitution, event)}
                error={!!nameError}
              />
            </FormControlApp>
          </StyleWrapperInputsBox>
          <StyleWrapperInputsBox>
            <FormControlApp
              label={labels.institutionForm.phoneNumberText}
              errorMessage={phoneNumberError}
            >
              <InputApp
                name="phoneNumber"
                value={phoneNumber}
                onChange={handleChangePhoneNumber}
                error={!!phoneNumberError}
              />
            </FormControlApp>
          </StyleWrapperInputsBox>
        </StyleContainerInputsBox>
        <StyleContainerInputsBox>
          <StyleWrapperInputsBox>
            <FormControlApp label={labels.institutionForm.cityText} errorMessage={cityError}>
              <InputApp name="city" value={city} onChange={handleChangeCity} error={!!cityError} />
            </FormControlApp>
          </StyleWrapperInputsBox>
          <StyleWrapperInputsBox>
            <FormControlApp
              label={labels.institutionForm.rucInstitutionText}
              errorMessage={rucError}
            >
              <InputApp
                name="rucInstitution"
                value={rucInstitution}
                onChange={handleChangeRucInstitution}
                error={!!rucError}
              />
            </FormControlApp>
          </StyleWrapperInputsBox>
        </StyleContainerInputsBox>
        <StyleWrapperInputsBox>
          <FormControlApp label={labels.login.email} errorMessage={emailError}>
            <InputApp
              name="email"
              value={email}
              onChange={handleChangeEmail}
              error={!!emailError}
            />
          </FormControlApp>
        </StyleWrapperInputsBox>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          gap: '20px',
          marginTop: '183px',
        }}
      >
        <Link to={institutionsListUrl} style={{ textDecoration: 'none' }}>
          <Box sx={{ width: '220px' }}>
            <ButtonApp isOutlined isFullWidth>
              {backText}
            </ButtonApp>
          </Box>
        </Link>
        <ButtonApp isDisabled={isDisabledButton} onClick={handleCreateOrUpdateInstitution}>
          {submitButtonText}
        </ButtonApp>
      </Box>
    </form>
  )
}

export default memo(InstitutionForm)
