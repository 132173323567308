import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { PromotionController } from 'infraestructure/controllers/promotionController'
import { Status } from 'domain/models/apiResponse'
import { UpdatePromotionCommitteeCommandRequest } from 'domain/entities/promotion'
import {
  promotionState,
  updatePromotionCommitteeError,
  updatePromotionCommitteeStart,
  updatePromotionCommitteeSuccess,
} from 'presentation/redux/slices/promotionSlice'
import useError from 'presentation/hooks/useError'

export const useUpdatePromotionCommittee = () => {
  const dispatch = useDispatch()
  const { isLoading, errorUpdateCommittee } = useSelector(promotionState)
  const { getErrorMessage } = useError()

  const updatePromotionCommittee = useCallback(
    async (
      promotionId: string,
      representativeId: string,
      data: UpdatePromotionCommitteeCommandRequest,
    ) => {
      dispatch(updatePromotionCommitteeStart())
      try {
        const response = await PromotionController.updatePromotionCommittee(
          promotionId,
          representativeId,
          data,
        )
        if (response?.status === Status.success) {
          dispatch(updatePromotionCommitteeSuccess())
          return true
        }
      } catch (err: any) {
        dispatch(updatePromotionCommitteeError(getErrorMessage({ error: err })))
        return false
      }
    },
    [dispatch, getErrorMessage],
  )

  return { updatePromotionCommittee, isLoading, errorUpdateCommittee }
}
