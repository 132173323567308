import axios from 'axios'
import promise from 'promise'

import { AsyncApiResponse, Status } from 'domain/models/apiResponse'
import { RequestProps, Token } from 'infraestructure/dataSources/http/types'
import {
  handleRefreshToken,
  makeErrorResponse,
  makeResponse,
} from 'infraestructure/dataSources/http/helpers'

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_CORE_API_HOST, // API_URL,
})

axiosInstance.interceptors.request.use(
  (response) => {
    const url = response?.url as string
    const token: Token = JSON.parse(sessionStorage.getItem('accessToken') || '{}')
    if (token.accessToken && !url.includes('refresh-token')) {
      response.headers.Authorization = `Bearer ${token.accessToken.replace(/[ '"]+/g, '')}`
    }
    return response
  },
  (error) => {
    return promise.reject(error)
  },
)

export const sendRequest = async <T>(props: RequestProps): Promise<AsyncApiResponse<T>> => {
  let response = null
  try {
    if (props.get) {
      response = await axiosInstance.get(props.get.path, props.config)
    }
    if (props.post) {
      response = await axiosInstance.post(props.post.path, props.post.body, props.config)
    }
    if (props.delete) {
      response = await axiosInstance.post(props.delete.path, props.config)
    }
  } catch (error: any) {
    if (error?.response?.status === Status.unauthorized) {
      return await handleRefreshToken(props)
    }
    throw makeErrorResponse<T>(error)
  }
  return makeResponse<T>(response)
}
