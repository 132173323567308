import { ReactNode } from 'react'
import { Box, useTheme } from '@mui/material'

import { TextSubTitle } from 'presentation/components/elements/typography'

type PagesHeaderProps = {
  titleText: string
  children?: ReactNode
  colorTitleText?: string
}

export default function PagesHeader({
  titleText,
  children,
  colorTitleText = 'primary.main',
}: PagesHeaderProps) {
  const theme = useTheme()

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
        padding: '0 0 15px 67px',
        borderBottom: `1px solid ${theme.palette.primary.main}30`,
      }}
    >
      <TextSubTitle fontSize="24px" lineHeight="30px" color={colorTitleText}>
        {titleText}
      </TextSubTitle>
      {children}
    </Box>
  )
}
