import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'

export const StyleContainerInputsBox = styled(Box)(() => ({
  display: 'flex',
  gap: '27px',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
}))

export const StyleWrapperInputsBox = styled(Box)(() => ({
  maxWidth: '490px',
  width: '100%',
}))

export const StyledTooltips = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.info.main,
    padding: '10px',
    borderRadius: '10px',
    width: '175px',
    fontSize: '13px',
    fontWeight: 400,
    lineHeight: '17px',
    textAlign: 'start',
    border: '1px solid',
    boxShadow: 'Offset 0, 0 rgba(76, 113, 252, 0.3)',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.info.main,
  },
}))
