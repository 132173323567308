import { ChangeEvent, useEffect, useRef, useState } from 'react'

import { StyledBox } from 'presentation/pages/adminManagement/components/adminManagementTable/index.styled'
import { StyledTableCell } from 'presentation/styles/index.styled'
import EditIcon from 'presentation/assets/edit.svg'
import { TextParagraph } from 'presentation/components/elements/typography'
import { editText } from 'presentation/constant/globals'
import { GridContainer, GridItem } from 'presentation/components/elements/grid'
import { StyledTextarea } from './index.styled'

type AdminManagementTableCommentCellProps = {
  comment: string
  width: string
  handleUpdate: any
  id: string
}

export default function TableCommentCell({
  comment,
  width,
  handleUpdate,
  id,
}: AdminManagementTableCommentCellProps) {
  const [valueInput, setValueInput] = useState<string>(comment || '')
  const [isShowInput, setIsShowInput] = useState<boolean>(false)
  const inputReference = useRef<HTMLTextAreaElement>(null)
  const [isInputValueChanged, setIsInputValueChanged] = useState<boolean>(false)
  const handleShowInput = () => {
    setIsShowInput(true)
  }
  const handleHideInput = () => {
    setIsShowInput(false)
    setIsInputValueChanged(false)
  }

  const handleSubmit = async () => {
    if (isInputValueChanged || valueInput !== comment) {
      const response = await handleUpdate(id, valueInput)
      if (response) {
        handleHideInput()
      }
    } else {
      handleHideInput()
    }
  }

  useEffect(() => {
    if (isShowInput) {
      inputReference?.current?.focus()
    }
  }, [isShowInput])

  const handleChangeInput = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setValueInput(String(event.target.value))
    setIsInputValueChanged(true)
  }

  return (
    <StyledTableCell sx={{ maxWidth: width, width: '100%' }}>
      {isShowInput ? (
        <StyledTextarea
          name="comment"
          value={valueInput}
          maxRows={3}
          minRows={1}
          onChange={handleChangeInput}
          ref={inputReference}
          onBlur={handleSubmit}
        />
      ) : (
        <GridContainer alignItems="center">
          <GridItem md={11}>{valueInput || '-'}</GridItem>
          <GridItem md={1}>
            <StyledBox
              sx={{ marginTop: '5px', gap: '8px', alignSelf: 'flex-end' }}
              onClick={handleShowInput}
            >
              <img alt={EditIcon} src={EditIcon} width="24px" />
              <TextParagraph fontSize="10px" fontWeight={300} lineHeight="10px">
                {editText}
              </TextParagraph>
            </StyledBox>
          </GridItem>
        </GridContainer>
      )}
    </StyledTableCell>
  )
}
