import { lazy } from 'react'

import { urlRoutes } from 'presentation/routes/config/urlRoutes/urlRoutes'

const AdminManagement = lazy(() => import('presentation/pages/adminManagement'))
const CreateAdmin = lazy(() => import('presentation/pages/createAdmin'))

export const adminManagementRoutes = [
	{
		nameUrl: 'Página de Gestión de administradores',
		path: urlRoutes.private.adminManagement.url,
		element: AdminManagement,
		rols: ['*']
	},
	{
		nameUrl: 'Página de Creación de administrador',
		path: urlRoutes.private.creatAdmin.url,
		element: CreateAdmin,
		rols: ['*']
	}
]
