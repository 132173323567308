import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { PromotionController } from 'infraestructure/controllers/promotionController'
import { Status } from 'domain/models/apiResponse'
import {
  promotionState,
  updatePromotionError,
  updatePromotionStart,
  updatePromotionSuccess,
} from 'presentation/redux/slices/promotionSlice'
import useError from 'presentation/hooks/useError'

export const useInactivePromotion = () => {
  const dispatch = useDispatch()
  const { isLoading, errorUpdatePromotion } = useSelector(promotionState)
  const { getErrorMessage } = useError()

  const inactivePromotion = useCallback(
    async (promotionId: string, active: boolean) => {
      dispatch(updatePromotionStart())
      try {
        const response = await PromotionController.inactivePromotion(promotionId, active)
        if (response?.status === Status.success) {
          dispatch(updatePromotionSuccess())
          return true
        }
      } catch (err: any) {
        dispatch(updatePromotionError(getErrorMessage({ error: err })))
        return false
      }
    },
    [dispatch, getErrorMessage],
  )

  return { inactivePromotion, isLoading, errorUpdatePromotion }
}
