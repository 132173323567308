import { lazy } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import { urlRoutes } from 'presentation/routes/config/urlRoutes/urlRoutes'
import { FooterLinkRoutes } from 'presentation/routes/publicPages/footerLinkRoutes/footerLinksRoutes'
import { AuthRoutes } from './authRoutes/authRoutes'

const NotFound = lazy(() => import('presentation/pages/notFound404'))

export const publicRoutes = [...AuthRoutes, ...FooterLinkRoutes]

export default function PublicPages() {
  return (
    <Routes>
      <Route path="*" element={<NotFound />} />
      <Route path="/" element={<Navigate to={urlRoutes.public.auth.login.url} replace />} />
      {publicRoutes.map((routes) => (
        <Route key={routes.path} path={routes.path} element={<routes.element />} />
      ))}
    </Routes>
  )
}
