import { Box, useTheme } from '@mui/material'

import { Notification } from 'domain/entities/notification'
import { BackdropPopup, ButtonApp, TextBold, TextParagraph } from 'presentation/components/elements'
import { backText } from 'presentation/constant/globals'
import { StyledBox } from './index.styled'

type HeaderNotificationsModalProps = {
  isOpenModal: boolean
  handleCloseModal: () => void
  notification: Notification
}

export default function HeaderNotificationsModal({
  isOpenModal,
  handleCloseModal,
  notification,
}: HeaderNotificationsModalProps) {
  const theme = useTheme()

  return (
    <BackdropPopup openBackdrop={isOpenModal}>
      <Box sx={{ width: '500px' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '20px',
          }}
        >
          <TextBold color={theme.palette.info.main}>{notification.message}</TextBold>
          <StyledBox>
            {notification?.details?.map((item: string) => (
              <TextParagraph key={item}>{item} </TextParagraph>
            ))}
          </StyledBox>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '40px' }}>
          <ButtonApp onClick={handleCloseModal}>{backText}</ButtonApp>
        </Box>
      </Box>
    </BackdropPopup>
  )
}
