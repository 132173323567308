import { uploadImageCommand } from 'application/commands/image'
import ImageRepository from 'infraestructure/repositories/imageRepository'

const imageRepository = new ImageRepository()

export class ImageController {
  static async uploadImage(file: FormData) {
    return await uploadImageCommand(imageRepository, file)
  }
}
