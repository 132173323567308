import { Box } from '@mui/material'

import { StyledTableCell, StyledTableRow } from 'presentation/styles/index.styled'

type TableEmptyDataProps = {
  isLoading: boolean
  columnsNumber: number
}
export default function TableEmptyData({ isLoading, columnsNumber }: TableEmptyDataProps) {
  return (
    <StyledTableRow>
      <StyledTableCell align="center" colSpan={columnsNumber}>
        <Box
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
          }}
        >
          {isLoading ? 'Cargando datos...' : 'No existe información para mostrar'}
        </Box>
      </StyledTableCell>
    </StyledTableRow>
  )
}
