import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { NotificationController } from 'infraestructure/controllers/notificationController'
import { Query } from 'domain/models/query'
import { Status } from 'domain/models/apiResponse'
import { ReadNotificationsQueryResponse } from 'domain/entities/notification'
import {
  getAllNotificationsError,
  getAllNotificationsStart,
  getAllNotificationsSuccess,
  notificationState,
} from 'presentation/redux/slices/notificationSlice'
import useError from 'presentation/hooks/useError'

export const useGetAllNotifications = () => {
  const dispatch = useDispatch()
  const { isLoading, error, notifications, pagination } = useSelector(notificationState)
  const { getErrorMessage } = useError()

  const getAllNotifications = useCallback(
    async (query?: Query) => {
      dispatch(getAllNotificationsStart())
      try {
        const response = await NotificationController.getAllNotifications(query)
        if (response?.status === Status.success) {
          dispatch(getAllNotificationsSuccess(response?.data as ReadNotificationsQueryResponse))
        }
      } catch (err: any) {
        dispatch(getAllNotificationsError(getErrorMessage({ error: err })))
      }
    },
    [dispatch, getErrorMessage],
  )

  return { getAllNotifications, isLoading, error, notifications, pagination }
}
