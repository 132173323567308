import { useDispatch, useSelector } from 'react-redux'

import { AuthUserController } from 'infraestructure/controllers/authUserController'
import { RecoverPasswordRepresentativeCommandResponse } from 'domain/entities/authUser'
import { Status } from 'domain/models/apiResponse'
import {
  authUserState,
  recoverPasswordError,
  recoverPasswordStart,
  recoverPasswordSuccess,
} from 'presentation/redux/slices/authUserSlice'
import useError from 'presentation/hooks/useError'

export const useRecoverPassword = () => {
  const dispatch = useDispatch()
  const { isLoading, error } = useSelector(authUserState)
  const { getErrorMessage } = useError()
  const recoverPassword = async (id: string) => {
    dispatch(recoverPasswordStart())
    try {
      const response = await AuthUserController.recoverPassword(id)
      if (response?.status === Status.success) {
        dispatch(
          recoverPasswordSuccess(response.data as RecoverPasswordRepresentativeCommandResponse),
        )
        return true
      }
    } catch (err) {
      dispatch(recoverPasswordError(getErrorMessage({ error: err })))
    }
  }

  return { recoverPassword, isLoading, error }
}
