import { QueryPagination } from 'domain/models/queryPagination'
import { Admin } from 'domain/entities/admin'

export type AdminState = {
  isLoading: boolean
  error: string | null
  errorCreateOrUpdate: string | null
  admins: Admin[]
  pagination: QueryPagination
}

export const userEmpty = {
  id: '',
  fullName: '',
  city: '',
  identificationNumber: '',
  cellphone: '',
  email: '',
  password: '',
  active: false,
  comments: '',
}
