import moment from 'moment'

export const validatePass = (valor: any) => {
  const regExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#&()–[{}\]:;',?/*~$^+=<>|.%])/ // NOSONAR
  return regExp.test(valor)
}

export const validateOnlyNumber = (valor: any) => {
  const regExp = /^\d*$/
  return regExp.test(valor)
}

export const validateCellPhone = (valor: any) => {
  const regExp = /^[+]?\d*$/
  return regExp.test(valor)
}

export const validateIdentification = (value: any) => {
  const regExp = /^[\da-zA-Z]+$/ // NOSONAR
  return regExp.test(value)
}

export const validateNames = (value: any) => {
  const regExp = /^[a-zA-ZÀ-ÿ\s´]+$/u // NOSONAR
  return regExp.test(value)
}

export const validateNumbersAndLetters = (value: any) => {
  const regExp = /^[\da-zA-ZÀ-ÿ\s]+$/ // NOSONAR
  return regExp.test(value)
}

export const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/) // NOSONAR
}

export const formatUtcToLocal = (date: string, format?: string) => {
  return moment(date)
    .utc()
    .local()
    .format(format || 'DD/MM/YYYY')
}

export const convertStringPrice = (value: number) =>
  value?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
