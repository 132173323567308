import { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { PromotionController } from 'infraestructure/controllers/promotionController'
import { Status } from 'domain/models/apiResponse'
import { CreatePromotionCommitteeCommandRequest } from 'domain/entities/promotion'
import {
  createPromotionCommitteeConflictError,
  createPromotionCommitteeError,
  createPromotionCommitteeStart,
  createPromotionCommitteeSuccess,
  promotionState,
} from 'presentation/redux/slices/promotionSlice'
import useError from 'presentation/hooks/useError'

export const useCreatePromotionCommittee = () => {
  const [isEditPromotionAddCommitteeAlertPopup, setIsEditPromotionAddCommitteeAlertPopup] =
    useState<boolean>(false)
  const dispatch = useDispatch()
  const { isLoading, errorCreateCommittee } = useSelector(promotionState)
  const { getErrorMessage } = useError()

  const createPromotionCommittee = useCallback(
    async (promotionId: string, data: CreatePromotionCommitteeCommandRequest) => {
      dispatch(createPromotionCommitteeStart())
      try {
        const response = await PromotionController.createPromotionCommittee(promotionId, data)
        if (response?.status === Status.success) {
          dispatch(createPromotionCommitteeSuccess())
          return true
        }
      } catch (err: any) {
        if (err?.error?.status === Status.conflict) {
          setIsEditPromotionAddCommitteeAlertPopup(true)
          dispatch(createPromotionCommitteeConflictError())
        } else {
          dispatch(createPromotionCommitteeError(getErrorMessage({ error: err })))
        }
        return false
      }
    },
    [dispatch, getErrorMessage],
  )
  const handleCloseEditPromotionAddCommitteeAlertPopup = () => {
    setIsEditPromotionAddCommitteeAlertPopup(false)
  }

  return {
    createPromotionCommittee,
    isLoading,
    errorCreateCommittee,
    isEditPromotionAddCommitteeAlertPopup,
    handleCloseEditPromotionAddCommitteeAlertPopup,
  }
}
