import { styled } from '@mui/material/styles'
import { TextareaAutosize } from '@mui/base'

export const StyledTextarea = styled(TextareaAutosize)(({ theme }) => ({
  overflow: 'hidden',
  border: `1px solid ${theme.palette.info.light}`,
  borderRadius: '6px',
  fontSize: '13px',
  padding: '5px',
  fontFamily: 'Roboto',
  width: '100%',
}))
