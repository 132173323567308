import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'

export const StyleContainerInputsBox = styled(Box)(() => ({
  display: 'flex',
  gap: '27px',
  flexWrap: 'wrap',
}))

export const StyleWrapperInputsBox = styled(Box)(() => ({
  maxWidth: '490px',
  width: '100%',
}))
