import {
  AuthLoginRepresentativeRequest,
  ResetPasswordAuthUserCommandRequest,
} from 'domain/entities/authUser'
import {
  LoginRepresentativeCommand,
  LogoutRepresentativeCommand,
  recoverPasswordRepresentativeCommand,
  refreshTokenRepresentativeCommand,
  resetPasswordRepresentativeCommand,
} from 'application/commands/authUser'
import AuthUserRepository from 'infraestructure/repositories/authUserRepository'

const authUserRepository = new AuthUserRepository()

export class AuthUserController {
  static async login(data: AuthLoginRepresentativeRequest) {
    return await LoginRepresentativeCommand(authUserRepository, data)
  }

  static async logout(refreshToken: string) {
    return await LogoutRepresentativeCommand(authUserRepository, refreshToken)
  }

  static async recoverPassword(identificationNumber: string) {
    return await recoverPasswordRepresentativeCommand(authUserRepository, identificationNumber)
  }

  static async refreshToken(refreshToken: string) {
    return await refreshTokenRepresentativeCommand(authUserRepository, refreshToken)
  }

  static async resetPassword(data: ResetPasswordAuthUserCommandRequest) {
    return await resetPasswordRepresentativeCommand(authUserRepository, data)
  }
}
