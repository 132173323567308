import { Menu } from '@mui/material'
import { styled } from '@mui/material/styles'

export const StyledMenu = styled(Menu)(({ theme }) => ({
  overflow: 'hidden',
  marginTop: '25px',
  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',

  '& .MuiMenuItem-root.Mui-selected': {
    '&:hover': {
      color: theme.palette.info.main,
      fontWeight: 700,
    },
    p: {
      fontWeight: 700,
    },
    marginLeft: '10px',
  },
  '& .MuiMenuItem-root:hover': {
    color: theme.palette.info.main,
    fontWeight: 700,
  },
  /* '&:before': {
     content: '""',
     display: 'block',
     position: 'absolute',
     top: 0,
     right: 14,
     width: 10,
     height: 10,
     transform: 'translateY(-50%) rotate(45deg)',
   }, */
}))
