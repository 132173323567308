import { useDispatch, useSelector } from 'react-redux'

import { AuthUserController } from 'infraestructure/controllers/authUserController'
import { ResetPasswordAuthUserCommandRequest } from 'domain/entities/authUser'
import { Status } from 'domain/models/apiResponse'
import {
  authUserState,
  resetPasswordError,
  resetPasswordStart,
  resetPasswordSuccess,
} from 'presentation/redux/slices/authUserSlice'
import useError from 'presentation/hooks/useError'

export const useResetPassword = () => {
  const dispatch = useDispatch()
  const { isLoading, error } = useSelector(authUserState)
  const { getErrorMessage } = useError()
  const resetPassword = async (data: ResetPasswordAuthUserCommandRequest) => {
    dispatch(resetPasswordStart())
    try {
      const response = await AuthUserController.resetPassword(data)
      if (response?.status === Status.success) {
        dispatch(resetPasswordSuccess())
        return true
      }
    } catch (err) {
      dispatch(resetPasswordError(getErrorMessage({ error: err })))
    }
  }

  return { resetPassword, isLoading, error }
}
