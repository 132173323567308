import { lazy } from 'react'

import { urlRoutes } from 'presentation/routes/config/urlRoutes/urlRoutes'

const DestinationAdministration = lazy(() => import('presentation/pages/destinationAdministration'))
const EditDestination = lazy(() => import('presentation/pages/editDestination'))
export const destinationAdministrationRoutes = [
  {
    nameUrl: 'Administración de destinos',
    path: urlRoutes.private.destinationAdministration.url,
    element: DestinationAdministration,
    rols: ['*'],
  },
  {
    nameUrl: 'Edición de destino',
    path: urlRoutes.private.editDestination.url,
    element: EditDestination,
    rols: ['*'],
  },
]
