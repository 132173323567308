import { Query } from 'domain/models/query'
import { readGetAllPromotionsQuery, readGetPromotionQuery } from 'application/queries/promotion'
import {
  createPromotionCommand,
  createPromotionCommitteeCommand,
  inactivePromotionCommand,
  updatePromotionCommand,
  updatePromotionCommitteeCommand,
  updatePromotionSetDelegateCommand,
} from 'application/commands/promotion'
import PromotionRepository from 'infraestructure/repositories/promotionRepository'
import {
  CreatePromotionCommandRequest,
  CreatePromotionCommitteeCommandRequest,
  UpdatePromotionCommandRequest,
  UpdatePromotionCommitteeCommandRequest,
} from 'domain/entities/promotion'

const promotionRepository = new PromotionRepository()

export class PromotionController {
  static async getAllPromotions(institutionId: string, query?: Query) {
    return await readGetAllPromotionsQuery(promotionRepository, institutionId, query)
  }

  static async getPromotion(promotionId: string) {
    return await readGetPromotionQuery(promotionRepository, promotionId)
  }

  static async createPromotion(institutionId: string, data: CreatePromotionCommandRequest) {
    return await createPromotionCommand(promotionRepository, institutionId, data)
  }

  static async updatePromotion(promotionId: string, data: UpdatePromotionCommandRequest) {
    return await updatePromotionCommand(promotionRepository, promotionId, data)
  }

  static async inactivePromotion(promotionId: string, active: boolean) {
    return await inactivePromotionCommand(promotionRepository, promotionId, active)
  }

  static async createPromotionCommittee(
    promotionId: string,
    data: CreatePromotionCommitteeCommandRequest,
  ) {
    return await createPromotionCommitteeCommand(promotionRepository, promotionId, data)
  }

  static async updatePromotionCommittee(
    promotionId: string,
    representativeId: string,
    data: UpdatePromotionCommitteeCommandRequest,
  ) {
    return await updatePromotionCommitteeCommand(
      promotionRepository,
      promotionId,
      representativeId,
      data,
    )
  }

  static async updatePromotionSetDelegate(promotionId: string, representativeId: string) {
    return await updatePromotionSetDelegateCommand(
      promotionRepository,
      promotionId,
      representativeId,
    )
  }
}
