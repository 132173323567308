import { styled } from '@mui/material/styles'
import { Switch } from '@mui/material'
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'

export const AntSwitch = styled(Switch)(({ theme }) => ({
  width: '86.5px',
  height: '21.8px',
  padding: 0,
  display: 'flex',
  alignItems: 'center',
  borderRadius: 10,
  '& .MuiSwitch-switchBase': {
    padding: 1,
    '&.Mui-checked': {
      transform: 'translateX(40px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.mixins.scrollBar.color,
      },
      '& .MuiSwitch-thumb': { backgroundColor: theme.palette.info.main },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: '45px',
    height: '20px',
    borderRadius: 10,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
    backgroundColor: theme.palette.secondary.main,
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    boxSizing: 'border-box',
    backgroundColor: theme.mixins.scrollBar.color,
  },
}))

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.info.main,
    padding: '10px',
    borderRadius: '5px',
    width: '175px',
    fontSize: '12px',
    fontWeight: 300,
    lineHeight: '13px',
    textAlign: 'center',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.info.main,
  },
}))
