import { lazy } from 'react'
import { Route, Routes } from 'react-router-dom'
import { documentManagementRoutes } from './documentManagement'
import { institutionAdministrationRoutes } from './institutionAdministration'
import { adminManagementRoutes } from './adminManagement'
import { promotionAdministrationRoutes } from './promotionAdministration'
import { packageAdministrationRoutes } from './pacakgeAdministration'
import { destinationAdministrationRoutes } from './destinationAdministration'

const NotFound = lazy(() => import('presentation/pages/notFound404'))

export const privateRoutes = [
  ...documentManagementRoutes,
  ...institutionAdministrationRoutes,
  ...adminManagementRoutes,
  ...promotionAdministrationRoutes,
  ...packageAdministrationRoutes,
  ...destinationAdministrationRoutes,
]

type PrivatePagesProps = {
  rol: string
}

export default function PrivatePages({ rol }: PrivatePagesProps) {
  return (
    <Routes>
      <Route path="*" element={<NotFound />} />
      {privateRoutes.map((routes: any) => {
        if (routes?.rols.find((rols: any) => rols === rol || rols === '*')) {
          return <Route key={routes.path} path={routes.path} element={<routes.element />} />
        }
        return null
      })}
    </Routes>
  )
}
