import IPromotionRepository from 'domain/interfaces/repositories/IPromotionRepository'
import {
  CreatePromotionCommandRequest,
  CreatePromotionCommandResponse,
  CreatePromotionCommitteeCommandRequest,
  CreatePromotionCommitteeCommandResponse,
  ReadGetPromotionQueryResponse,
  ReadPromotionsQueryResponse,
  UpdatePromotionCommandRequest,
  UpdatePromotionCommandResponse,
  UpdatePromotionCommitteeCommandRequest,
  UpdatePromotionCommitteeCommandResponse,
  UpdatePromotionSetDelegateCommandResponse,
} from 'domain/entities/promotion'
import { Query } from 'domain/models/query'
import { sendRequest } from 'infraestructure/dataSources/http/axios'
import { getQueryString } from 'infraestructure/utils/format'
import { AsyncApiResponse } from 'domain/models/apiResponse'
import {
  COMMITTEE,
  CORE_API_BASE,
  CREATE,
  PROMOTIONS,
  REPRESENTATIVES,
  UPDATE,
} from './endpoints.routes'

export default class PromotionRepository implements IPromotionRepository {
  async getAllPromotions(
    institutionId: string,
    query: Query,
  ): AsyncApiResponse<ReadPromotionsQueryResponse> {
    const queryOrder = query
      ? `?orders=createAt__DESC&${getQueryString(query)}`
      : '?orders=createAt__DESC&'
    const request = {
      get: {
        path: `${CORE_API_BASE}/${institutionId}/${PROMOTIONS}${queryOrder}`,
      },
    }
    return await sendRequest<ReadPromotionsQueryResponse>(request)
  }

  async getPromotion(promotionId: string): AsyncApiResponse<ReadGetPromotionQueryResponse> {
    const request = {
      get: {
        path: `${CORE_API_BASE}/${promotionId}/committee`,
      },
    }
    return await sendRequest<ReadGetPromotionQueryResponse>(request)
  }

  async createPromotion(
    institutionId: string,
    data: CreatePromotionCommandRequest,
  ): AsyncApiResponse<CreatePromotionCommandResponse> {
    const request = {
      post: {
        path: `${CORE_API_BASE}/${institutionId}/${PROMOTIONS}/${CREATE}`,
        body: data,
      },
    }
    return await sendRequest<CreatePromotionCommandResponse>(request)
  }

  async updatePromotion(
    promotionId: string,
    data: UpdatePromotionCommandRequest,
  ): AsyncApiResponse<UpdatePromotionCommandResponse> {
    const request = {
      post: {
        path: `${CORE_API_BASE}/${PROMOTIONS}/${promotionId}/${UPDATE}`,
        body: data,
      },
    }
    return await sendRequest<UpdatePromotionCommandResponse>(request)
  }

  async inactivePromotion(
    promotionId: string,
    active: boolean,
  ): AsyncApiResponse<UpdatePromotionCommandResponse> {
    const request = {
      post: {
        path: `${CORE_API_BASE}/${promotionId}/lock`,
        body: { active },
      },
    }
    return await sendRequest<UpdatePromotionCommandResponse>(request)
  }

  async createPromotionCommittee(
    promotionId: string,
    data: CreatePromotionCommitteeCommandRequest,
  ): AsyncApiResponse<CreatePromotionCommitteeCommandResponse> {
    const request = {
      post: {
        path: `${CORE_API_BASE}/${promotionId}/${REPRESENTATIVES}/${COMMITTEE}/${CREATE}`,
        body: data,
      },
    }
    return await sendRequest<CreatePromotionCommitteeCommandResponse>(request)
  }

  async updatePromotionCommittee(
    promotionId: string,
    representativeId: string,
    data: UpdatePromotionCommitteeCommandRequest,
  ): AsyncApiResponse<UpdatePromotionCommitteeCommandResponse> {
    const request = {
      post: {
        path: `${CORE_API_BASE}/${promotionId}/${REPRESENTATIVES}/${COMMITTEE}/${representativeId}/${UPDATE}`,
        body: data,
      },
    }
    return await sendRequest<UpdatePromotionCommitteeCommandResponse>(request)
  }

  async updatePromotionSetDelegate(
    promotionId: string,
    representativeId: string,
  ): AsyncApiResponse<UpdatePromotionSetDelegateCommandResponse> {
    const request = {
      post: {
        path: `${CORE_API_BASE}/${promotionId}/${REPRESENTATIVES}/${COMMITTEE}/${representativeId}/set-delegate`,
        body: {},
      },
    }
    return await sendRequest<UpdatePromotionSetDelegateCommandResponse>(request)
  }
}
