import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { v4 as generateId } from 'uuid'
import moment from 'moment'

import { CreateOrUpdatePackageCommandRequest, Package } from 'domain/entities/pacakge'
import { Item, ItemEmpty } from 'domain/entities/package'
import { FormData, schema } from 'presentation/components/elements/packageForm/schema'
import { useUploadImage } from 'presentation/redux/hooks/image'
import { formatUtcToLocal } from 'presentation/utils/format'
import { saveChangesText } from 'presentation/constant/globals'
import { addPackageButtonText } from 'presentation/constant/texts/packageAdministration'

type UsePackageFormProps = {
  packageData?: Package
  handleSave: (data: CreateOrUpdatePackageCommandRequest) => void
}
export const usePackageForm = ({ packageData, handleSave }: UsePackageFormProps) => {
  const [includeList, setIncludeList] = useState<Item[]>([])
  const [notIncludeList, setNotIncludeList] = useState<Item[]>([])
  const [observationsList, setObservationsList] = useState<Item[]>([])
  const [include, setInclude] = useState<Item>(ItemEmpty)
  const [notInclude, setNotInclude] = useState<Item>(ItemEmpty)
  const [observation, setObservation] = useState<Item>(ItemEmpty)
  const [rating, setRating] = useState<number | null>(null)
  const [startDate, setStartDate] = useState<Date | string>('')
  const [endDate, setEndDate] = useState<Date | string>('')
  const {
    register,
    formState: { errors },
    setValue,
    watch,
    setError,
    clearErrors,
  } = useForm<FormData>({
    mode: 'onChange',
    shouldFocusError: false,
    resolver: yupResolver(schema),
  })
  const {
    image,
    isLoadingUploadImage,
    uploadImageError,
    errorResponseUploadImage,
    handleChangeImage,
    handleClearError,
  } = useUploadImage({ imageUrl: packageData?.imageUrl, setValue })
  const START_DATE = 'startDate'
  const END_DATE = 'endDate'

  const generateDataList = (list: string[]) => {
    let newList: Item[] = []
    list?.map((item: string) => {
      newList = [...newList, { id: generateId(), name: item }]
    })
    return newList
  }

  useEffect(() => {
    if (packageData) {
      setValue('packageId', packageData?.packageId)
      setValue('destination', packageData?.destinationName)
      setValue('packageName', packageData?.name)
      setValue('description', packageData?.description)
      setValue('hotel', packageData?.hotel)
      setValue('numberPersonByIncludePrice', packageData?.persons)
      setValue('maxCapacity', packageData?.maxCapacity)
      setValue('nights', packageData?.nights)
      setValue('days', packageData?.days)
      setValue('price', packageData?.value)
      setValue('image', packageData?.imageUrl)
      if (packageData?.startDate) {
        setValue(START_DATE, packageData?.startDate)
        setStartDate(packageData?.startDate)
      }
      if (packageData?.endDate) {
        setValue(END_DATE, packageData?.endDate)
        setEndDate(packageData?.endDate)
      }
      setValue('rating', packageData?.hotelStars)
      setRating(packageData?.hotelStars)
      setIncludeList(generateDataList(packageData?.include))
      setNotIncludeList(generateDataList(packageData?.notInclude))
      setObservationsList(generateDataList(packageData?.conditionsAndObservations))
    }
  }, [packageData])

  const handleSavePackage = () => {
    const getNamesArray = (list: Item[]) => {
      return list.map((item: Item) => item.name)
    }

    const data = {
      hotel: watch().hotel,
      persons: watch().numberPersonByIncludePrice,
      maxCapacity: watch().maxCapacity,
      days: watch().days,
      nights: watch().nights,
      hotelStars: rating as number,
      startDate: formatUtcToLocal(watch().startDate, 'YYYY-MM-DD'),
      endDate: formatUtcToLocal(watch().endDate, 'YYYY-MM-DD'),
      include: getNamesArray(includeList),
      notInclude: getNamesArray(notIncludeList),
      conditionsAndObservations: getNamesArray(observationsList),
      value: watch().price,
      imageUrl: image,
      description: watch().description,
      name: watch().packageName,
      destinationName: watch().destination,
      packageId: watch().packageId,
    }
    handleSave(data)
  }
  const handleChangeStartDate = (value: Date) => {
    const date = moment(value)
    setValue(START_DATE, date.toString())
    if (!date.isValid()) {
      setError(START_DATE, {
        type: 'custom',
        message: 'La fecha inicial no es válida',
      })
      setValue(START_DATE, '')
    } else if (date.format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')) {
      setError(START_DATE, {
        type: 'custom',
        message: 'La fecha inicial no puede ser menor a la actual',
      })
      setValue(START_DATE, '')
    } else {
      setStartDate(value)
      clearErrors(START_DATE)
    }
  }
  const handleChangeEndDate = (value: Date) => {
    const date = moment(value)
    setValue(END_DATE, date.toString())
    if (!date.isValid()) {
      setError(END_DATE, {
        type: 'custom',
        message: 'La fecha final no es válida',
      })
      setValue(END_DATE, '')
    } else if (date.format('YYYY-MM-DD') < moment(startDate).format('YYYY-MM-DD')) {
      setError(END_DATE, {
        type: 'custom',
        message: 'La fecha final no puede ser menor a la actual',
      })
      setValue(END_DATE, '')
    } else {
      setEndDate(value)
      clearErrors(END_DATE)
    }
  }

  const handleChangeRating = (event: unknown, nextValue: number | null) => {
    setValue('rating', nextValue as number)
    setRating(nextValue as number)
  }
  const handleSetIncludeInput = (value: string) => {
    setValue('include', value)
  }

  const handleSetNotIncludeInput = (value: string) => {
    setValue('notInclude', value)
  }

  const handleSetObservationInput = (value: string) => {
    setValue('observation', value)
  }

  const handleAddItem = (
    setList: Dispatch<SetStateAction<Item[]>>,
    list: Item[],
    inputValue: string,
  ) => {
    setList([...list, { id: generateId(), name: inputValue }])
  }

  const handleAddItemToIncludeList = () => {
    handleAddItem(setIncludeList, includeList, watch().include)
    handleSetIncludeInput('')
  }

  const handleAddItemToNotIncludeList = () => {
    handleAddItem(setNotIncludeList, notIncludeList, watch().notInclude)
    handleSetNotIncludeInput('')
  }

  const handleAddItemToObservationsList = () => {
    handleAddItem(setObservationsList, observationsList, watch().observation)
    handleSetObservationInput('')
  }

  const handleEditElement = (
    listElements: Item[],
    elementToEdit: Item,
    inputValue: string,
    setList: Dispatch<SetStateAction<Item[]>>,
    setElement: Dispatch<SetStateAction<Item>>,
  ) => {
    const indexFounded = listElements.findIndex((item: Item) => item?.id === elementToEdit?.id)
    const listItems: Item[] = [...listElements]
    if (indexFounded >= 0) {
      listItems[indexFounded] = {
        ...elementToEdit,
        ...{
          name: inputValue,
        },
      }
      setList(listItems)
      setElement(ItemEmpty)
    }
  }

  const handleEditItemFromIncludeList = () => {
    handleEditElement(includeList, include, watch().include, setIncludeList, setInclude)
    handleSetIncludeInput('')
  }

  const handleEditItemFromNotIncludeList = () => {
    handleEditElement(
      notIncludeList,
      notInclude,
      watch().notInclude,
      setNotIncludeList,
      setNotInclude,
    )
    handleSetNotIncludeInput('')
  }

  const handleEditItemFromObservationList = () => {
    handleEditElement(
      observationsList,
      observation,
      watch().observation,
      setObservationsList,
      setObservation,
    )
    handleSetObservationInput('')
  }

  const handleDeleteItem = (
    id: string,
    list: Item[],
    setList: Dispatch<SetStateAction<Item[]>>,
    setElementToEdit: Dispatch<SetStateAction<Item>>,
  ) => {
    const includeListFiltered = list.filter((element: Item) => element.id !== id)
    setList(includeListFiltered)
    setElementToEdit(ItemEmpty)
  }

  const handleDeleteItemFromIncludeList = (id: string) => {
    handleDeleteItem(id, includeList, setIncludeList, setInclude)
    handleSetIncludeInput('')
  }

  const handleDeleteItemFromNotIncludeList = (id: string) => {
    handleDeleteItem(id, notIncludeList, setNotIncludeList, setNotInclude)
    handleSetNotIncludeInput('')
  }
  const handleDeleteItemFromObservationsList = (id: string) => {
    handleDeleteItem(id, observationsList, setObservationsList, setObservation)
    handleSetObservationInput('')
  }
  const handleSetInclude = (item: Item) => {
    setInclude(item)
    handleSetIncludeInput(item.name)
  }

  const handleSetNotInclude = (item: Item) => {
    setNotInclude(item)
    handleSetNotIncludeInput(item.name)
  }

  const handleSetObservation = (item: Item) => {
    setObservation(item)
    handleSetObservationInput(item.name)
  }

  const isDisabledButton =
    !includeList.length ||
    !notIncludeList.length ||
    !observationsList.length ||
    !watch().rating ||
    !watch().packageId ||
    !watch().destination ||
    !watch().packageName ||
    !watch().description ||
    !watch().hotel ||
    !watch().numberPersonByIncludePrice ||
    !watch().maxCapacity ||
    !watch().nights ||
    !watch().days ||
    !watch().startDate ||
    !watch().endDate ||
    !watch().price ||
    !image ||
    !!Object.values(errors).length

  const saveButtonText = packageData ? saveChangesText : addPackageButtonText

  return {
    register,
    watch,
    errors,
    setValue,
    handleChangeStartDate,
    handleChangeEndDate,
    includeList,
    notIncludeList,
    observationsList,
    handleAddItemToIncludeList,
    handleAddItemToNotIncludeList,
    handleAddItemToObservationsList,
    handleDeleteItemFromIncludeList,
    handleDeleteItemFromNotIncludeList,
    handleDeleteItemFromObservationsList,
    isDisabledButton,
    saveButtonText,
    handleSetInclude,
    handleSetNotInclude,
    handleSetObservation,
    include,
    notInclude,
    observation,
    handleEditItemFromIncludeList,
    handleEditItemFromNotIncludeList,
    handleEditItemFromObservationList,
    rating,
    handleChangeRating,
    startDate,
    endDate,
    handleSavePackage,
    isLoadingUploadImage,
    image,
    handleChangeImage,
    uploadImageError,
    errorResponseUploadImage,
    handleClearError,
  }
}
