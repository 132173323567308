import { Query } from 'domain/models/query'
import IPromotionRepository from 'domain/interfaces/repositories/IPromotionRepository'

export const readGetAllPromotionsQuery = (
  repository: IPromotionRepository,
  institutionId: string,
  query?: Query,
) => {
  return repository.getAllPromotions(institutionId, query)
}
